/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { PortalUser } from './portal-user';


export interface ApiClient { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: ApiClient.StatusEnum;
    deactivatedBy?: PortalUser;
    clientCode?: string;
    clientSecret?: string;
    description?: string;
    name?: string;
    systemType?: ApiClient.SystemTypeEnum;
    secretEncrypted?: boolean;
    encryptionVersion?: string;
    actorRole?: string;
    portalAccount?: PortalAccount;
}
export namespace ApiClient {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type SystemTypeEnum = 'GATEWAY_CONTROLLER' | 'POPULATION_REGISTRY' | 'THIRD_PARTY_SYSTEM' | 'UIN_GENERATOR' | 'ENROLLMENT_SERVER' | 'CIVIL_REGISTRY' | 'CREDENTIAL_MANAGEMENT_SYSTEM';
    export const SystemTypeEnum = {
        GatewayController: 'GATEWAY_CONTROLLER' as SystemTypeEnum,
        PopulationRegistry: 'POPULATION_REGISTRY' as SystemTypeEnum,
        ThirdPartySystem: 'THIRD_PARTY_SYSTEM' as SystemTypeEnum,
        UinGenerator: 'UIN_GENERATOR' as SystemTypeEnum,
        EnrollmentServer: 'ENROLLMENT_SERVER' as SystemTypeEnum,
        CivilRegistry: 'CIVIL_REGISTRY' as SystemTypeEnum,
        CredentialManagementSystem: 'CREDENTIAL_MANAGEMENT_SYSTEM' as SystemTypeEnum
    };
}


