/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUser } from './portal-user';


export interface PortalAccount { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: PortalAccount.StatusEnum;
    deactivatedBy?: PortalUser;
    name?: string;
    type?: PortalAccount.TypeEnum;
    code?: string;
    approvalStatus?: PortalAccount.ApprovalStatusEnum;
}
export namespace PortalAccount {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type TypeEnum = 'EME' | 'ORGANIZATION' | 'SYSTEM_SUPPORT';
    export const TypeEnum = {
        Eme: 'EME' as TypeEnum,
        Organization: 'ORGANIZATION' as TypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as TypeEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum
    };
}


