/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApiClient } from './api-client';
import { PortalUser } from './portal-user';


export interface Topic { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: Topic.StatusEnum;
    deactivatedBy?: PortalUser;
    name?: string;
    topicId?: string;
    approvalStatus?: Topic.ApprovalStatusEnum;
    reasonForDisapproval?: string;
    requestingSystem?: string;
    organizationName?: string;
    dueDate?: string;
    averageMessageSizeInBytes?: number;
    maximumMessageSizeInBytes?: number;
    retryIntervalInSeconds?: number;
    maxRetry?: number;
    description?: string;
    systemEventClass?: Topic.SystemEventClassEnum;
    systemDefinedTopic?: boolean;
    retentionSupported?: boolean;
    ownedBy?: ApiClient;
}
export namespace Topic {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum
    };
    export type SystemEventClassEnum = 'ENROLLMENT' | 'CREDENTIAL' | 'CUSTOMER' | 'PARTNER_VENDOR' | 'BILLING' | 'TOKENIZATION' | 'UIN' | 'REFERENCE' | 'VERIFICATION' | 'OTHER';
    export const SystemEventClassEnum = {
        Enrollment: 'ENROLLMENT' as SystemEventClassEnum,
        Credential: 'CREDENTIAL' as SystemEventClassEnum,
        Customer: 'CUSTOMER' as SystemEventClassEnum,
        PartnerVendor: 'PARTNER_VENDOR' as SystemEventClassEnum,
        Billing: 'BILLING' as SystemEventClassEnum,
        Tokenization: 'TOKENIZATION' as SystemEventClassEnum,
        Uin: 'UIN' as SystemEventClassEnum,
        Reference: 'REFERENCE' as SystemEventClassEnum,
        Verification: 'VERIFICATION' as SystemEventClassEnum,
        Other: 'OTHER' as SystemEventClassEnum
    };
}


