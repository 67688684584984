import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../../custom-validators";

@Component({
  selector: 'app-reason-for-disapproval-dialog',
  templateUrl: './reason-for-disapproval-dialog.component.html',
  styleUrls: ['./reason-for-disapproval-dialog.component.css']
})
export class ReasonForDisapprovalDialogComponent implements OnInit {

  @Input() infoMessage:string = 'Provide a reason for this action';
  @Input() inputLabel:string = 'Enter reason for deactivation';
  @Input() modalHeader:string = 'Deactivate';
  @Input() buttonLabel:string = 'Deactivate';
  @Input() maxInputLength:number = 250;

  characterCount: number = 0;
  characterCountMessage: string;

  // @Input() onProcessed: () => void;

  @Output() onProcessed:  EventEmitter<string> = new EventEmitter();

  @Input() buttonColor: 'btn-danger' | 'btn-primary' | 'btn-success' | 'btn-warning' = 'btn-danger';


  errMessage = '';
  showErrorMessageTrigger = false;
  form: FormGroup;
  constructor(
    private bsModalRef: BsModalRef,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      reason: ['', [Validators.required, CustomValidators.notBlank, CustomValidators.minLength(2)]]
    });

    this.form.get('reason').valueChanges.subscribe((value) => {
      if (value) {
        this.characterCount = value.length;
        this.characterCountMessage = `${this.characterCount} / ${this.maxInputLength}`;
      } else {
        this.characterCountMessage = '';
      }

    });
  }

  close(): void {
    // this.onSuccessEmitter.emit();
    this.bsModalRef.hide();
  }

  disapprove(){
    if(this.form.invalid){
      return this.showErrorMessage("Please enter reason to proceed");
    }

    // this.onConfirm(this.form.get('reason').value);
    this.onProcessed.emit(this.form.get('reason').value);
    this.close();
  }



  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 20000);
  }


}
