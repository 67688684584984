/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewTopicDto { 
    topicName: string;
    organizationName: string;
    dueDate?: string;
    maxMessageSize?: number;
    averageMessageSize?: number;
    retryIntervalInMintues?: number;
    maxRetry?: number;
    description?: string;
    clientCode?: string;
    systemEventClass: NewTopicDto.SystemEventClassEnum;
}
export namespace NewTopicDto {
    export type SystemEventClassEnum = 'ENROLLMENT' | 'CREDENTIAL' | 'CUSTOMER' | 'PARTNER_VENDOR' | 'BILLING' | 'TOKENIZATION' | 'UIN' | 'REFERENCE' | 'VERIFICATION' | 'OTHER';
    export const SystemEventClassEnum = {
        Enrollment: 'ENROLLMENT' as SystemEventClassEnum,
        Credential: 'CREDENTIAL' as SystemEventClassEnum,
        Customer: 'CUSTOMER' as SystemEventClassEnum,
        PartnerVendor: 'PARTNER_VENDOR' as SystemEventClassEnum,
        Billing: 'BILLING' as SystemEventClassEnum,
        Tokenization: 'TOKENIZATION' as SystemEventClassEnum,
        Uin: 'UIN' as SystemEventClassEnum,
        Reference: 'REFERENCE' as SystemEventClassEnum,
        Verification: 'VERIFICATION' as SystemEventClassEnum,
        Other: 'OTHER' as SystemEventClassEnum
    };
}


