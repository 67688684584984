<div class="h6">
  Total records: <span>{{searchManager?.totalCount}}</span>

  <ng-container *ngIf="searchManager?.totalAmount">
    &nbsp;|  &nbsp;

    <span >Total amount (₦): <span>{{searchManager?.totalAmount | amount}}</span> </span>
  </ng-container>

</div>
