import {Component, Input, OnInit} from '@angular/core';
import {SearchManager} from "../../search/search-manager";

@Component({
  selector: 'app-total-records',
  templateUrl: './total-records.component.html',
  styleUrls: ['./total-records.component.css']
})
export class TotalRecordsComponent implements OnInit {

  @Input()
  searchManager:SearchManager<any,any>;
  constructor() { }

  ngOnInit(): void {
  }

}
