/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TopicConfiguration { 
    minRetryIntervalInSeconds?: number;
    maxRetryIntervalInSeconds?: number;
    minRetryCount?: number;
    maxRetryCount?: number;
    defaultRetryIntervalInSeconds?: number;
    defaultRetryCount?: number;
    invalidRetryIntervalMessage?: string;
    invalidRetryCountMessage?: string;
}

