import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {KeycloakService} from "keycloak-angular";
import {Router} from "@angular/router";
import {AsyncSubject, from, Observable, of, Subject} from "rxjs";
import {KeycloakLoginOptions} from "keycloak-js";
import {PageManager} from '../misc/page-manager';
import {User} from '../components/models/user/user.model';
import {environment} from "../../../environments/environment";
import {
  AccountMembershipPojo,
  ActivityLogDto,
  ActivityLogSearchControllerService,
  AuditTrailControllerService
} from 'sdk/eme-api-sdk';
import PermissionsEnum = AccountMembershipPojo.PermissionsEnum;
import {tap} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private static user: Subject<User | null> = new Subject();
  public static _user: User;
  private static ongoingFetch: Observable<any> | null;
  private static initialized: boolean;

  private static newUserToken: EventEmitter<string | null> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    private keycloak: KeycloakService,
    private router: Router,
    private pageManager: PageManager,
    private activityLogService: AuditTrailControllerService
  ) {
    AuthenticationService.user.subscribe((user: any) => {
      if (user === undefined) {
        return;
      }
      AuthenticationService.initialized = true;
      AuthenticationService._user = user;
    });
  }

  public clearStaleSession() {
    const redirect = AuthenticationService._user;
    AuthenticationService.user.next(null);
    localStorage.clear();
    sessionStorage.clear();
    this.pageManager.clearAllData()
    if (redirect) {
      location.href = this.router.createUrlTree(['/']).toString();
    }
  }

  public logout(redirectUri?: string) {
    const STORAGE_KEY = 'isFirstLogin';
    localStorage.setItem(STORAGE_KEY, 'true');
    this.activityLogService.logLogoutAction({ auditTrailDto: {} }).subscribe((res) => {
      this.clearStaleSession();
      return from(this.keycloak.logout(redirectUri)).pipe(
        tap((x) => AuthenticationService.user.next(undefined))
      );
    });
    return new Observable<void>();
  }

  public login(loginOptions: KeycloakLoginOptions) {
    return this.keycloak.login(loginOptions);
  }

  public requestPasswordReset(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}/password/forgot`, data);
  }

  public getUser() {
    return AuthenticationService.user;
  }

  public forbidAccess() {
    this.router.navigate(['/forbidden']);
  }

  public fetchUser(): Observable<User> {
    if (AuthenticationService.initialized) {
      return of(AuthenticationService._user);
    }
    return this.fetch();
  }

  public hasAnyPermission(permissions: string[] | PermissionsEnum[]) {
    for (let permission of permissions) {
      if (this.hasPermission(permission)) {
        return true;
      }
    }
    return false;
  }

  hasRole(role: string): boolean {
    return this.pageManager.currentUserAccount$.getValue()?.roles?.find(value => value == role) != null;
  }

  hasAnyRole(roles: string[]): boolean {
    for (let role of roles) {
      if (this.hasRole(role)) {
        return true;
      }
    }
    return false;
  }

  public hasPermission(permissionName: string | PermissionsEnum) {
    return this.permissions().filter((it: string) => it === permissionName).length;
  }

  public hasAccountType(accountType: string) {
    return this.pageManager.currentUserAccount$.getValue().accountType === accountType;
  }

  private permissions(): string[] {
    let account = this.pageManager.currentUserAccount$.value;
    if (!account) {
      return [];
    }
    return account.permissions;
  }

  private fetch() {

    const wrapper = new AsyncSubject();
    AuthenticationService.ongoingFetch = wrapper;

    this.httpClient.get(`${environment.apiBaseUrl}/me`)
      .subscribe((u: any) => {
        const user = new User(u);
        wrapper.next(user);
        wrapper.complete();

        AuthenticationService.user.next(user);
        this.pageManager.setCurrentUser(user);
        this.pageManager.setCurrentUserAccount(user.accounts[0]);

        AuthenticationService.ongoingFetch = null;
      }, (err: any) => {
        wrapper.error(err);
        wrapper.complete();
        AuthenticationService.user.next(null);
      });

    return AuthenticationService.ongoingFetch;
  }
}
