import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-api-guide',
  templateUrl: './api-guide.component.html',
  styleUrls: ['./api-guide.component.css']
})
export class ApiGuideComponent implements OnInit {


  @HostListener('window:scroll', ['$event'])
  onScroll(e:any) {
    let siteFooterCood = document.querySelector('footer.site_footer')?.getBoundingClientRect();
    let sfcTop = siteFooterCood?.top ? siteFooterCood?.top : 0;

    // this.asidePosition = {
    //   top: sfcTop < window.innerHeight ? 210 - ( window.innerHeight - sfcTop) : 210,
    //   bottom: sfcTop < window.innerHeight ? sfcTop : 0,
    // }

    try {
      let sectionLinks = document.querySelectorAll('.nav-link');

      sectionLinks.forEach((e:any) => {
        if (this.isElementInViewport(document.getElementById(e.getAttribute('data-target-id') || ''))) {
          this.fragment = e;
          throw 'Break Exception'
        }
      })
    } catch (error) {
      // console.log(error);

    }
  }

  sample_message:string = `[
    {
        "serviceName" : "Enrollment Service",
        "serviceCode" : "001",
        "serviceItem" : [
            {
                "name": "Enrolment - Data Capture",
                "code" : "001-001",
                "status": "active | inactive"
            },
            {
                "name": "Enrolment and NIN Issuance - Persons 16 yrs and above",
                "code" : "001-002",
                "status": "active | inactive"
            },
            ...
        ],
        "status" : "active | inactive"
    },
    ...
]
`
  comment_2:string = `[
    {
        "code": "NM0089",
        "jurisdiction", "local | diaspora_african | diaspora_non_african",
        "status": "active | inactive"
    },
]
`

  sample_message_2:string = `[
    {
      “customerName” : “Verify Technologies”
      “customerId” : “N438903”,
      “account” : [
          {
              "code": "NM0089",
              "jurisdiction", "local | diaspora_african | diaspora_non_african",
              "status": "active | inactive"
          },
          …
      ],
      “phoneNumber” : “08134899908”
      “email” : “admin@verifytech.com”
      “customerType” : “organisation”,
      “organisationType” : “corporate”,
      “registrationNumber” : “RC09293”,
      “sector” : “private”,
      “streetAddress” : “1 Gloucester Road, Wuse”,
      “addressLine2” : “”
      “postalCode” : “900201”,
      “townOrCity” : “Abuja”,
      “state” : “FCT”,
      “country” : “Nigeria”,
      “status” : “active”

    },
        …
]
`
sample_message_3:string = `
  {
    “serviceItemName” : “Person Verification Service - Level Two”,
    “serviceItemCode” : “002-002”,
    “customerAccountCode” : "NM0089",
    “tpsServiceTransactionId” : “sf2425345tertet893”,
    “reasonForService” : “kyc”,
    “tpsSystemCode” : “mws”,
    “tpsTransactionTime” : “2022-10-09 14:34:32.345”,
    “tpsTransactionType” : “verify_id”,
    “nin” : “12009444787”,
    “ninToken” : “1738994400557389”
  }
`

sample_message_4:string = `
  {
    “code” : “1001”,
    “message” : “Invalid service item code [045-930]”,
    “tpsServiceTransactionId” : “sf2425345tertet893”
  }
`
sample_message_5:string = `
  {
    “customerAccountCode” : “NM0089”,
    “tpsServiceTransactionId” : “sf2425345tertet893”,
    “billingBlockchainReference” : “adf23442sdwfs454lklldgwwd4”,
    “billingFulfillmentTime” : “2022-10-09 14:34:32.345”,
  }
`

sample_message_6:string = `
  {
    “customerId” : “234311321”,
    “customerAccountCode” : “NM0089”,
    “reason” : “Past due obligation”,
    “billingRequestId” : “adf23442sdwwer44lklldgwwd4”,
    “billingRequestTime” : “2022-10-09 14:34:32.345”,
    “billingRequestType” : “severe”,
}
`

finger_sample_message:string = `[
  {
    "biographicData": {
        "firstName": "John",
        "lastName": "Doo",
        "dateOfBirth": "1985-11-30",
        "gender": "M",
        "nationality": "FRA",
        "...": "..."
    },
    "biometricData": [
        {
            "biometricType": "FINGER",
            "biometricSubType": "RIGHT_INDEX",
            "instance": "string",
            "image": "c3RyaW5n",
            "imageRef": "http://imageserver.com/image?id=00003",
            "captureDate": "2019-05-21T12:00:00Z",
            "captureDevice": "string",
            "impressionType": "LIVE_SCAN_PLAIN",
            "width": 1,
            "height": 1,
            "bitdepth": 1,
            "mimeType": "string",
            "resolution": 1,
            "compression": "WSQ",
            "missing": [
                {
                    "biometricSubType": "RIGHT_INDEX",
                    "presence": "BANDAGED"
                }
            ],
            "metadata": "string",
            "comment": "string",
            "template": "c3RyaW5n",
            "templateRef": "http://dataserver.com/template?id=00014",
            "templateFormat": "string",
            "quality": 1,
            "qualityFormat": "string",
            "algorithm": "string",
            "vendor": "string"
        }
    ],
    "credentialData": [
        {
            "credentialNumber": "string",
            "personId": "string",
            "credentialType": "ID_CARD",
            "issuedDate": "2022-01-03T16:14:04.461348",
            "expiryDate": "2022-01-03T16:14:04.461348",
            "serialNumber": "string",
            "issuingAuthority": "string",
            "issuingPlace": "string",
            "others": {
                "...": "..."
            }
        }
    ],
    "contactData": {
        "email": "John.Doo@osia.com",
        "phone1": "555666777",
        "phone2": "555888999",
        "...": "..."
    }
  }
]
`

face_sample_message:string = `[
  {
    "biographicData": {
        "firstName": "John",
        "lastName": "Doo",
        "dateOfBirth": "1985-11-30",
        "gender": "M",
        "nationality": "FRA",
        "...": "..."
    },
    "biometricData": [
        {
            "biometricType": "FACE",
            "biometricSubType": "POTRAIT",
            "instance": "string",
            "image": "c3RyaW5n",
            "imageRef": "http://imageserver.com/image?id=00003",
            "captureDate": "2019-05-21T12:00:00Z",
            "captureDevice": "string",
            "impressionType": "LIVE_SCAN_PLAIN",
            "width": 1,
            "height": 1,
            "bitdepth": 1,
            "mimeType": "string",
            "resolution": 1,
            "compression": "PNG",
            "metadata": "string",
            "comment": "string",
            "template": "c3RyaW5n",
            "templateRef": "http://dataserver.com/template?id=00014",
            "templateFormat": "string",
            "quality": 1,
            "qualityFormat": "string",
            "algorithm": "string",
            "vendor": "string"
        }
    ],
    "credentialData": [
        {
            "credentialNumber": "string",
            "personId": "string",
            "credentialType": "ID_CARD",
            "issuedDate": "2022-01-03T16:14:04.461348",
            "expiryDate": "2022-01-03T16:14:04.461348",
            "serialNumber": "string",
            "issuingAuthority": "string",
            "issuingPlace": "string",
            "others": {
                "...": "..."
            }
        }
    ],
    "contactData": {
        "email": "John.Doo@osia.com",
        "phone1": "555666777",
        "phone2": "555888999",
        "...": "..."
    }
  }
]
`
sample_message_7:string = `
  {
    “billingRequestId” : “adf23442sdwwer44lklldgwwd4”,
    “customerAccountCode” : “NM0089”,
    “tpsSystemCode” : “hclone”,
    “requestCompletionTime” : “2022-10-09 14:34:32.345”
  }
`

comment_3:string = `[
  {
    "name" : "Enrolment - Data Capture",
    "code" : "001-001",
    "status": "active | inactive"
  }
]
`
get_all_topics:string = `[
  {
  "id": 0,
  "uuid": "string",
  "name": "string"
  }
]
`

get_all_topics_success:string = `[
  {
  "id": 0,
  "uuid": "string",
  "name": "string"
  }
]
`

post_topics:string = `[
  {
  "id": 0,
  "uuid": "string",
  "name": "string"
  }
]
`

post_topics_success:string = `
  {}

`



bad_response:string = `[
  {
    "code": 0,
    "message": "string"
  }
]
`

bad_response_smile:string = `[
  {
    "code": 0,
    "message": "string"
  }
]
`


bad_response_success:string = `[
  {
    "code": 0,
    "message": "string"
  }
]

`
get_all_subscriptions:string = `[
  {
    "uuid": "string",
    "topic": "string",
    "protocol": "http",
    "address": "string",
    "policy": "string",
    "active": true
  }
]
`

new_person_sample_message:string = `
  {
    "physicalStatus": "ALIVE",
    "uin": "44546025915",
    "personId": "VRPS649344",
    "identityTransactionId": "VRPS649343",
    "identityId": "2OODXCN5DQ00001",
    "identityStatus": "VALID",
    "reasonForIdentityStatusChange": "",
    "firstName": "SANDRA",
    "lastName": "DOE",
    "middleName": "JAMES",
    "otherName": "IKOT",
    "dateOfBirth": "1999-12-26T00:00",
    "gender": "FEMALE",
    "birthCountry": "nigeria",
    "height": "155",
    "nationality": "nigeria",
    "originState": "Delta",
    "originCountry": "nigeria",
    "residenceAddressLine1": "NO 98 CHURCH ROAD",
    "residenceTown": "BONNY ISLAND",
    "residenceLga": "Bonny",
    "residenceState": "Rivers",
    "residenceCountry": "nigeria",
    "operatorUserId": "88469522708",
    "enrollmentDate": "23-06-2021",
    "enrollmentTrackingId": "2OODXCNSGCEGETG",
    "biometricDataList": [
      {
        "type": "FACE",
        "referencePath": "https://eme..com/api/v1/files/63e510094c309040f39b9ae5"
      },
      {
        "type": "SIGNATURE",
        "referencePath": "https:/eme..com/api/v1/files/63e510064c309040f39b9ae4"
      }
    ]
  }
`

death_report_sample_message:string = `
{
  "physicalStatus": "DEAD",
  "deathVerification": "DERIVED",
  "uin": "249870828490",
  "personId": "VRPS648810",
  "identityTransactionId": "VRPS648809",
  "identityId": "187093EIJMIDEDFD",
  "identityStatus": "VALID",
  "reasonForIdentityStatusChange": "",
  "firstName": "ALADIN",
  "lastName": "DAMBARAWO",
  "middleName": "ADAMU",
  "otherName": "ABDULLAHI",
  "dateOfBirth": "1990-12-17T00:00",
  "gender": "FEMALE",
  "birthCountry": "nigeria",
  "height": "170",
  "telephoneNumber": "08062465884",
  "nationality": "nigeria",
  "originCountry": "nigeria",
  "residenceAddressLine1": "NO 49B AUDU WAY MAITAMA ABUJA",
  "residenceTown": "FCT",
  "residenceLga": "Nassarawa",
  "residenceState": "Kano",
  "residenceCountry": "nigeria",
  "operatorUserId": "31224639410",
  "enrollmentDate": "22-06-2021",
  "enrollmentTrackingId": "1OZLHEDVMB00005",
  "biometricDataList": [
    {
      "type": "FACE",
      "referencePath": "https://eme.com/api/v1/files/63e51b9a53bbf8523bad7bce"
    },
    {
      "type": "SIGNATURE",
      "referencePath": "https://msg.nimc.gov.ng/api/v1/files/63e51b9853bbf8523bad7bcd"
    }
  ]
}
`

invalidation_event_sample_message:string = `
{
  "physicalStatus": "ALIVE",
  "uin": "44546025915",
  "personId": "VRPS649344",
  "identityTransactionId": "VRPS649343",
  "identityId": "2OODXCN5DQ00001",
  "identityStatus": "INVALID",
  "reasonForIdentityStatusChange": "",
  "firstName": "SANDRA",
  "lastName": "DOE",
  "middleName": "JAMES",
  "otherName": "IKOT",
  "dateOfBirth": "1999-12-26T00:00",
  "gender": "FEMALE",
  "birthCountry": "nigeria",
  "height": "155",
  "nationality": "nigeria",
  "originState": "Delta",
  "originCountry": "nigeria",
  "residenceAddressLine1": "NO 98 CHURCH ROAD",
  "residenceTown": "BONNY ISLAND",
  "residenceLga": "Bonny",
  "residenceState": "Rivers",
  "residenceCountry": "nigeria",
  "operatorUserId": "88469522708",
  "enrollmentDate": "23-06-2021",
  "enrollmentTrackingId": "2OODXCNSGCEGETG",
  "biometricDataList": [
    {
      "type": "FACE",
      "referencePath": "https://eme..com/api/v1/files/63e510094c309040f39b9ae5"
    },
    {
      "type": "SIGNATURE",
      "referencePath": "https:/eme..com/api/v1/files/63e510064c309040f39b9ae4"
    }
  ]
}
`

suspension_event_sample_message:string = `
{
  "physicalStatus": "ALIVE",
  "uin": "44546025915",
  "personId": "VRPS649344",
  "identityTransactionId": "VRPS649343",
  "identityId": "2OODXCN5DQ00001",
  "identityStatus": "SUSPENDED",
  "reasonForIdentityStatusChange": "",
  "firstName": "SANDRA",
  "lastName": "DOE",
  "middleName": "JAMES",
  "otherName": "IKOT",
  "dateOfBirth": "1999-12-26T00:00",
  "gender": "FEMALE",
  "birthCountry": "nigeria",
  "height": "155",
  "nationality": "nigeria",
  "originState": "Delta",
  "originCountry": "nigeria",
  "residenceAddressLine1": "NO 98 CHURCH ROAD",
  "residenceTown": "BONNY ISLAND",
  "residenceLga": "Bonny",
  "residenceState": "Rivers",
  "residenceCountry": "nigeria",
  "operatorUserId": "88469522708",
  "enrollmentDate": "23-06-2021",
  "enrollmentTrackingId": "2OODXCNSGCEGETG",
  "biometricDataList": [
    {
      "type": "FACE",
      "referencePath": "https://eme..com/api/v1/files/63e510094c309040f39b9ae5"
    },
    {
      "type": "SIGNATURE",
      "referencePath": "https:/eme..com/api/v1/files/63e510064c309040f39b9ae4"
    }
  ]
}
`

get_all_subscriptions_success:string = `[
  {
    "uuid": "string",
    "topic": "string",
    "protocol": "http",
    "address": "string",
    "policy": "string",
    "active": true
  }
]
`

new_vnin_sample:string = `
  {
    "token": "SE83990114647436",
    "uin": "23685344162",
    "customerAccountId": "N438903",
    "timestamp": "2022-12-10T14:51:57.904759",
    "expiryDate": "2022-12-10T14:51:57.904759"
  }
`


env:any = environment;

  public fragment: string = "";

  constructor(private route: ActivatedRoute, private scroller: ViewportScroller) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.setActive(fragment || '');
    });
    this.scroller.scrollToPosition([0,0]);
    this.fragment = "intro";
  }

  get fragmentValue(): string {
    return this.fragment;
  }

  setActive(section:string){
    this.fragment = section;
    console.log(this.fragment);
    this.scroller.scrollToAnchor(section);
  }

  isElementInViewport(el:HTMLElement | null) {
    if (!el) {

      return false;
    }

    var rect = el.getBoundingClientRect();
    var ih = (window.innerHeight / 5);

    return (
      rect.top <= ih  &&
      ( rect.bottom - 2 <= (rect.height  + ih)  && rect.bottom > ih)
        // rect.top >= 0 ||
        // // rect.left >= 0 &&
        // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */
        // // &&  rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

switchGetAllTopicsResponses(number: number) {
  switch (number) {
    case 200:
      this.get_all_topics = this.get_all_topics_success;
      break;
    default:
      this.get_all_topics = this.bad_response;
  }
}

switchPostTopicsResponses(number: number) {
  switch (number) {
    case 200:
      this.post_topics = this.post_topics_success;
      break;
    default:
      this.post_topics = this.bad_response;
  }
}


switchDeleteTopicsResponses(number: number) {
  switch (number) {
    case 400:
      this.bad_response = this.bad_response_success;
      break;
    default:
      this.bad_response = this.bad_response_smile;
  }
}


switchGetAllSubscriptionsResponses(number: number) {
  switch (number) {
    case 200:
      this.get_all_subscriptions = this.get_all_subscriptions_success;
      break;
    default:
      this.get_all_subscriptions = this.bad_response;
  }
}

getSDKUrl() {
  var a = document.createElement('a');
  a.href = '/assets/files/eme-client-0.0.1.jar'
  a.target = '_blank';
  a.download ='eme-client-0.0.1.jar';
  document.body.appendChild(a);
  a.click();
}

async copyCode(code: any) {
  try {
    await navigator.clipboard.writeText(code);
    alert('Code copied to clipboard.');
  } catch (err) {
    console.error('Failed to copy code: ', err);
  }
}


}
