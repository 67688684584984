/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuditTrailDto { 
    ipAddress?: string;
    entityName?: string;
    recordId?: number;
    transactionId?: string;
    recordType?: AuditTrailDto.RecordTypeEnum;
    eventType?: AuditTrailDto.EventTypeEnum;
    actorEventTime?: string;
    actorEmail?: string;
    actorUUID?: string;
    recipientEmail?: string;
    recipientUUID?: string;
    description?: string;
    actorRole?: Set<string>;
    actorPermission?: Set<string>;
    eventStatus?: AuditTrailDto.EventStatusEnum;
    resource?: string;
    resourceUri?: string;
    userAgent?: string;
    externalReference?: string;
    otherDetails?: string;
    authenticationMethodConstant?: AuditTrailDto.AuthenticationMethodConstantEnum;
    maskedNin?: string;
    attemptedAttack?: boolean;
    attemptedAttackType?: AuditTrailDto.AttemptedAttackTypeEnum;
    lastLoginTime?: string;
}
export namespace AuditTrailDto {
    export type RecordTypeEnum = 'LOGIN' | 'LOGOUT' | 'CREATE_USER' | 'CREATE_TOPIC' | 'VIEW_TOPIC' | 'SEARCH_TOPIC' | 'SEARCH_TOPIC_REQUEST' | 'CREATE_API_CLIENT' | 'VIEW_CLIENT_DETAILS' | 'EDIT_CLIENT' | 'DEACTIVATE_CLIENT' | 'ADD_ROLE' | 'APPROVE_NEW_TOPIC_REQUEST' | 'DISAPPROVE_NEW_TOPIC_REQUEST' | 'ACTIVATE_CLIENT' | 'UPDATE_CLIENT' | 'VIEW_SUBSCRIPTION' | 'UNSUBSCRIBE_FROM_SUBSCRIPTION' | 'REACTIVATE_SUBSCRIPTION' | 'DELETE_FROM_SUBSCRIPTION' | 'SEARCH_SUBSCRIPTION' | 'DEACTIVATE_SUBSCRIPTION' | 'UPDATE_SETTING' | 'ACTIVATE_TOPIC_REQUEST' | 'DEACTIVATE_TOPIC_REQUEST' | 'DELETE_TOPIC_REQUEST' | 'APPROVE_TOPIC_REQUEST' | 'DISAPPROVE_TOPIC_REQUEST' | 'API_CLIENT_SUBSCRIPTION' | 'API_CLIENT_SUBSCRIPTION_CONFIRMATION' | 'API_CLIENT_UNSUBSCRIPTION' | 'SEARCH_API_CLIENT' | 'CONFIGURE_CLIENT_TOPIC' | 'SEARCH_PENDING_MESSAGES' | 'LOGIN_ERROR' | 'ACTIVITY_LOG' | 'SEARCH_SETTING' | 'REPLAY_ATTACK' | 'MITM_ATTACK';
    export const RecordTypeEnum = {
        Login: 'LOGIN' as RecordTypeEnum,
        Logout: 'LOGOUT' as RecordTypeEnum,
        CreateUser: 'CREATE_USER' as RecordTypeEnum,
        CreateTopic: 'CREATE_TOPIC' as RecordTypeEnum,
        ViewTopic: 'VIEW_TOPIC' as RecordTypeEnum,
        SearchTopic: 'SEARCH_TOPIC' as RecordTypeEnum,
        SearchTopicRequest: 'SEARCH_TOPIC_REQUEST' as RecordTypeEnum,
        CreateApiClient: 'CREATE_API_CLIENT' as RecordTypeEnum,
        ViewClientDetails: 'VIEW_CLIENT_DETAILS' as RecordTypeEnum,
        EditClient: 'EDIT_CLIENT' as RecordTypeEnum,
        DeactivateClient: 'DEACTIVATE_CLIENT' as RecordTypeEnum,
        AddRole: 'ADD_ROLE' as RecordTypeEnum,
        ApproveNewTopicRequest: 'APPROVE_NEW_TOPIC_REQUEST' as RecordTypeEnum,
        DisapproveNewTopicRequest: 'DISAPPROVE_NEW_TOPIC_REQUEST' as RecordTypeEnum,
        ActivateClient: 'ACTIVATE_CLIENT' as RecordTypeEnum,
        UpdateClient: 'UPDATE_CLIENT' as RecordTypeEnum,
        ViewSubscription: 'VIEW_SUBSCRIPTION' as RecordTypeEnum,
        UnsubscribeFromSubscription: 'UNSUBSCRIBE_FROM_SUBSCRIPTION' as RecordTypeEnum,
        ReactivateSubscription: 'REACTIVATE_SUBSCRIPTION' as RecordTypeEnum,
        DeleteFromSubscription: 'DELETE_FROM_SUBSCRIPTION' as RecordTypeEnum,
        SearchSubscription: 'SEARCH_SUBSCRIPTION' as RecordTypeEnum,
        DeactivateSubscription: 'DEACTIVATE_SUBSCRIPTION' as RecordTypeEnum,
        UpdateSetting: 'UPDATE_SETTING' as RecordTypeEnum,
        ActivateTopicRequest: 'ACTIVATE_TOPIC_REQUEST' as RecordTypeEnum,
        DeactivateTopicRequest: 'DEACTIVATE_TOPIC_REQUEST' as RecordTypeEnum,
        DeleteTopicRequest: 'DELETE_TOPIC_REQUEST' as RecordTypeEnum,
        ApproveTopicRequest: 'APPROVE_TOPIC_REQUEST' as RecordTypeEnum,
        DisapproveTopicRequest: 'DISAPPROVE_TOPIC_REQUEST' as RecordTypeEnum,
        ApiClientSubscription: 'API_CLIENT_SUBSCRIPTION' as RecordTypeEnum,
        ApiClientSubscriptionConfirmation: 'API_CLIENT_SUBSCRIPTION_CONFIRMATION' as RecordTypeEnum,
        ApiClientUnsubscription: 'API_CLIENT_UNSUBSCRIPTION' as RecordTypeEnum,
        SearchApiClient: 'SEARCH_API_CLIENT' as RecordTypeEnum,
        ConfigureClientTopic: 'CONFIGURE_CLIENT_TOPIC' as RecordTypeEnum,
        SearchPendingMessages: 'SEARCH_PENDING_MESSAGES' as RecordTypeEnum,
        LoginError: 'LOGIN_ERROR' as RecordTypeEnum,
        ActivityLog: 'ACTIVITY_LOG' as RecordTypeEnum,
        SearchSetting: 'SEARCH_SETTING' as RecordTypeEnum,
        ReplayAttack: 'REPLAY_ATTACK' as RecordTypeEnum,
        MitmAttack: 'MITM_ATTACK' as RecordTypeEnum
    };
    export type EventTypeEnum = 'CREATE' | 'DELETE' | 'UPDATE' | 'SELECT' | 'LOGIN' | 'LOGOUT' | 'OPERATION_LOG' | 'GENERIC_LOG' | 'SIEM' | 'BIOMETRIC_LOGIN';
    export const EventTypeEnum = {
        Create: 'CREATE' as EventTypeEnum,
        Delete: 'DELETE' as EventTypeEnum,
        Update: 'UPDATE' as EventTypeEnum,
        Select: 'SELECT' as EventTypeEnum,
        Login: 'LOGIN' as EventTypeEnum,
        Logout: 'LOGOUT' as EventTypeEnum,
        OperationLog: 'OPERATION_LOG' as EventTypeEnum,
        GenericLog: 'GENERIC_LOG' as EventTypeEnum,
        Siem: 'SIEM' as EventTypeEnum,
        BiometricLogin: 'BIOMETRIC_LOGIN' as EventTypeEnum
    };
    export type EventStatusEnum = 'SUCCESSFUL' | 'FAILED';
    export const EventStatusEnum = {
        Successful: 'SUCCESSFUL' as EventStatusEnum,
        Failed: 'FAILED' as EventStatusEnum
    };
    export type AuthenticationMethodConstantEnum = 'PASSWORD' | 'FINGERPRINT';
    export const AuthenticationMethodConstantEnum = {
        Password: 'PASSWORD' as AuthenticationMethodConstantEnum,
        Fingerprint: 'FINGERPRINT' as AuthenticationMethodConstantEnum
    };
    export type AttemptedAttackTypeEnum = 'REPLAY' | 'MITM';
    export const AttemptedAttackTypeEnum = {
        Replay: 'REPLAY' as AttemptedAttackTypeEnum,
        Mitm: 'MITM' as AttemptedAttackTypeEnum
    };
}


