<div class="modal-header">
  <h5 class="modal-title"> {{modalHeader}} </h5>
  <button type="button" class="btn-close" (click)="close()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="mb-3" id="form" (validSubmit)="disapprove()">
    <div class="alert alert-info">
      <span [innerHTML]="infoMessage">You are about to deactivate <strong>Olalekan Ayomide’s</strong> account. Kindly confirm this action by providing a reason below and clicking deactivate.</span>
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label class="fw-bold" [innerHTML]="inputLabel"></label>
        <div
          [ngClass]="{'text-danger' : characterCount/maxInputLength == 1}"> {{characterCountMessage}} </div>
      </div>
<!--      <label class="fw-bold" [innerHTML]="inputLabel"></label>-->
      <textarea formControlName="reason" [maxlength]="maxInputLength" class="form-control" style="min-height: 130px; resize: unset;"></textarea>
      <bfv-messages></bfv-messages>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="submit" form="form" class="btn" [ngClass]="buttonColor">
    <span class="iconify icon"
                                                                 data-icon="fluent:flash-off-24-regular"></span> {{buttonLabel}}
  </button>
</div>
