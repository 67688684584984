import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-success-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent implements OnInit {

  @Input() message: any;
  @Input() contentWidth: string = '400px';

  @Output() onSuccessEmitter = new EventEmitter();

  constructor(
    private bsModalRef: BsModalRef
  ) {
  }

  ngOnInit(): void {
  }


  close(): void {
    this.onSuccessEmitter.emit();
    this.bsModalRef.hide();
  }

}
