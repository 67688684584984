/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SubscriptionSearchResponsePojo { 
    id?: number;
    systemName?: string;
    createdAt?: string;
    messagesReceived?: number;
    messagesLost?: number;
    status?: SubscriptionSearchResponsePojo.StatusEnum;
    subscriptionTopicName?: string;
    subscriptionId?: string;
    subscriptionUrl?: string;
    active?: boolean;
    confirmationRequested?: boolean;
}
export namespace SubscriptionSearchResponsePojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
}


