/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountMembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountId?: number;
    accountType?: AccountMembershipPojo.AccountTypeEnum;
    roles?: Set<string>;
    permissions?: Set<AccountMembershipPojo.PermissionsEnum>;
    otherData?: Array<object>;
}
export namespace AccountMembershipPojo {
    export type AccountTypeEnum = 'EME' | 'ORGANIZATION' | 'SYSTEM_SUPPORT';
    export const AccountTypeEnum = {
        Eme: 'EME' as AccountTypeEnum,
        Organization: 'ORGANIZATION' as AccountTypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as AccountTypeEnum
    };
    export type PermissionsEnum = 'CREATE_USER' | 'UPDATE_USER' | 'VIEW_USER' | 'DEACTIVATE_USER' | 'UPDATE_SETTING' | 'VIEW_SETTING' | 'CREATE_API_CLIENT' | 'VIEW_API_CLIENT' | 'EDIT_API_CLIENT' | 'MANAGE_SUBSCRIPTION' | 'APPROVE_TOPIC' | 'VIEW_API_GUIDE' | 'VIEW_AUDIT_TRAIL' | 'APPROVE_USER_REQUEST' | 'APPROVE_SUBSCRIPTION_REQUEST' | 'CREATE_TOPIC' | 'VIEW_TOPIC' | 'VIEW_SUBSCRIPTION' | 'VIEW_MESSAGE';
    export const PermissionsEnum = {
        CreateUser: 'CREATE_USER' as PermissionsEnum,
        UpdateUser: 'UPDATE_USER' as PermissionsEnum,
        ViewUser: 'VIEW_USER' as PermissionsEnum,
        DeactivateUser: 'DEACTIVATE_USER' as PermissionsEnum,
        UpdateSetting: 'UPDATE_SETTING' as PermissionsEnum,
        ViewSetting: 'VIEW_SETTING' as PermissionsEnum,
        CreateApiClient: 'CREATE_API_CLIENT' as PermissionsEnum,
        ViewApiClient: 'VIEW_API_CLIENT' as PermissionsEnum,
        EditApiClient: 'EDIT_API_CLIENT' as PermissionsEnum,
        ManageSubscription: 'MANAGE_SUBSCRIPTION' as PermissionsEnum,
        ApproveTopic: 'APPROVE_TOPIC' as PermissionsEnum,
        ViewApiGuide: 'VIEW_API_GUIDE' as PermissionsEnum,
        ViewAuditTrail: 'VIEW_AUDIT_TRAIL' as PermissionsEnum,
        ApproveUserRequest: 'APPROVE_USER_REQUEST' as PermissionsEnum,
        ApproveSubscriptionRequest: 'APPROVE_SUBSCRIPTION_REQUEST' as PermissionsEnum,
        CreateTopic: 'CREATE_TOPIC' as PermissionsEnum,
        ViewTopic: 'VIEW_TOPIC' as PermissionsEnum,
        ViewSubscription: 'VIEW_SUBSCRIPTION' as PermissionsEnum,
        ViewMessage: 'VIEW_MESSAGE' as PermissionsEnum
    };
}


