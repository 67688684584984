<div class="container pt-4 pt-md-3 pb-4 mb-2">
  <div class="welcome-wrapper flex-wrap flex-md-nowrap">
    <!-- <div *ngIf="routeData?.showWelcome">
      <h4 class="fw-bold fs-26 mt-4">Welcome to the VRPS setup wizard!</h4>
      <p class="fs-15 mb-4">
        Kindly carefully configure the form below and click on the Next
        button.
      </p>
    </div> -->
    <!-- <div class="d-flex card-bg p-3 mb-3 col-12 col-md-4 col-lg-3 col-xl-2" style="min-width: 230px;">
      <div class="me-2">
        <span class="iconify fs-22 text-header" data-icon="ant-design:user-outlined"></span>
      </div>
      <div>
        <p class="fs-14 fw-bold text-header mb-0 text-truncate">{{user?.firstName}} {{user?.lastName}}</p>
        <p class="fs-14 fw-light mb-0">{{currentAccount?.roles[0]}}</p>
      </div>
    </div> -->
  </div>

  <div class="dash-menu" *ngIf="!(routeData?.showWelcome)">
    <div class="">
      <div class="menu-toggle-btn-wrapper">
        <button class="menu-toggle-btn" data-hvas-target="#dash-navlist" data-hvas-toggle-class="show">
          <span class="iconify icon" data-icon="heroicons-solid:menu"></span>
          Menu
        </button>
      </div>

      <ul id="dash-navlist" class="dash-menu-navlist justify-content-center mb-3">
        <li><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/dashboard']"
               data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Dashboard</a></li>
        <li *ngIf="canViewTopics()"><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/topics']"
                                       data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Topics</a></li>
        <li *ngIf="canViewSubscriptions()"><a class="nav-item" [routerLinkActive]="'active'"
                                              [routerLink]="['/subscriptions']" data-hvas-target="#dash-navlist"
                                              data-hvas-remove-class="show">Subscriptions</a></li>
        <li *ngIf="canViewMessages()"><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/messages']"
                                         data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Messages</a>
        </li>
        <li *ngIf="canViewApiClients()"><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/clients']"
                                           data-hvas-target="#dash-navlist" data-hvas-remove-class="show">API
          Clients</a></li>
        <li *ngIf="canViewAuditTrail()"><a class="nav-item" [routerLinkActive]="'active'"
                                           [routerLink]="['/audit-trail']" data-hvas-target="#dash-navlist"
                                           data-hvas-remove-class="show">Audit Trail</a></li>
        <li *ngIf="canViewSettings()"><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/settings']"
                                         data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Settings</a>
        </li>
        <!-- <li><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/developers-guide']" data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Api Guide</a></li> -->
      </ul>
    </div>
  </div>
</div>
