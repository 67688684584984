/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { NewTopicDto } from '../model/models';
import { QueryResultsTopicRequestSearchResponsePojo } from '../model/models';
import { QueryResultsTopicSearchResponsePojo } from '../model/models';
import { TopicConfiguration } from '../model/models';
import { TopicPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateNewTopicRequestParams {
    newTopicDto: NewTopicDto;
}

export interface ManageTopicRequestRequestParams {
    id: number;
    approvalType: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    reason: string;
}

export interface RequestActionOnTopicRequestParams {
    id: number;
    requestType: 'ACTIVATE' | 'DEACTIVATE' | 'DELETE';
    reason: string;
}

export interface SearchTopicRequestParams {
    lastUpdatedAt?: string;
    dueDate?: string;
    averageMessageSizeInBytes?: number;
    description?: string;
    deactivatedBy?: string;
    createdAt?: string;
    reasonForDisapproval?: string;
    maximumMessageSizeInBytes?: number;
    retentionSupported?: boolean;
    id?: number;
    requestingSystem?: string;
    approvalStatus?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    lastUpdatedBy?: string;
    organizationName?: string;
    systemDefinedTopic?: boolean;
    retryIntervalInSeconds?: number;
    systemEventClass?: 'ENROLLMENT' | 'CREDENTIAL' | 'CUSTOMER' | 'PARTNER_VENDOR' | 'BILLING' | 'TOKENIZATION' | 'UIN' | 'REFERENCE' | 'VERIFICATION' | 'OTHER';
    maxRetry?: number;
    topicId?: string;
    createdBy?: string;
    clientCode?: string;
    ownedByClientCode?: string;
    name?: string;
    dateDeactivated?: string;
    topicName?: string;
    ownedBy?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    isNotApproved?: boolean;
    offset?: number;
    endDate?: string;
    systemConstants?: 'EME' | 'HGS' | 'HCLONE' | 'HVAS' | 'VRPS' | 'MVRD';
    organization?: string;
    subscribedToByClientId?: number;
    limit?: number;
    startDate?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface SearchTopicRequestsRequestParams {
    lastUpdatedBy?: string;
    requestReason?: string;
    lastUpdatedAt?: string;
    subscription?: string;
    reviewedBy?: string;
    type?: 'ACTIVATE_TOPIC' | 'DEACTIVATE_TOPIC' | 'DELETE_TOPIC' | 'DEACTIVATE_SUBSCRIPTION' | 'DELETE_SUBSCRIPTION' | 'ACTIVATE_USER' | 'DEACTIVATE_USER';
    createdAt?: string;
    reviewReason?: string;
    createdBy?: string;
    topic?: string;
    topicName?: string;
    topicName2?: string;
    id?: number;
    user?: string;
    status?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    offset?: number;
    endDate?: string;
    limit?: number;
    startDate?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class TopicsControllerService {

    protected basePath = 'http://eme.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * create Topics 
     * Can create for Topics
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNewTopic(requestParameters: CreateNewTopicRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<TopicPojo>;
    public createNewTopic(requestParameters: CreateNewTopicRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<TopicPojo>>;
    public createNewTopic(requestParameters: CreateNewTopicRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<TopicPojo>>;
    public createNewTopic(requestParameters: CreateNewTopicRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const newTopicDto = requestParameters.newTopicDto;
        if (newTopicDto === null || newTopicDto === undefined) {
            throw new Error('Required parameter newTopicDto was null or undefined when calling createNewTopic.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<TopicPojo>(`${this.configuration.basePath}/topic/create`,
            newTopicDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllActiveTopics(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<string>>;
    public getAllActiveTopics(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<string>>>;
    public getAllActiveTopics(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<string>>>;
    public getAllActiveTopics(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/topic/active-topics`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Count of Topics Pending Requests 
     * Can get count of Topics Requests
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPendingRequestCount(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<number>;
    public getPendingRequestCount(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<number>>;
    public getPendingRequestCount(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<number>>;
    public getPendingRequestCount(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<number>(`${this.configuration.basePath}/topic/request/pending/total`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTopicConfiguration(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<TopicConfiguration>;
    public getTopicConfiguration(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<TopicConfiguration>>;
    public getTopicConfiguration(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<TopicConfiguration>>;
    public getTopicConfiguration(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<TopicConfiguration>(`${this.configuration.basePath}/topic/configuration`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listTopics1(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<TopicPojo>>;
    public listTopics1(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<TopicPojo>>>;
    public listTopics1(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<TopicPojo>>>;
    public listTopics1(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<TopicPojo>>(`${this.configuration.basePath}/topic/list-topics`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Manage Topic Requests 
     * Can Manage Topics
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public manageTopicRequest(requestParameters: ManageTopicRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public manageTopicRequest(requestParameters: ManageTopicRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public manageTopicRequest(requestParameters: ManageTopicRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public manageTopicRequest(requestParameters: ManageTopicRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling manageTopicRequest.');
        }
        const approvalType = requestParameters.approvalType;
        if (approvalType === null || approvalType === undefined) {
            throw new Error('Required parameter approvalType was null or undefined when calling manageTopicRequest.');
        }
        const reason = requestParameters.reason;
        if (reason === null || reason === undefined) {
            throw new Error('Required parameter reason was null or undefined when calling manageTopicRequest.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (approvalType !== undefined && approvalType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>approvalType, 'approvalType');
        }
        if (reason !== undefined && reason !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reason, 'reason');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/topic/request/manage/${encodeURIComponent(String(id))}`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request Operations on Topics 
     * Can Request Topics
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestActionOnTopic(requestParameters: RequestActionOnTopicRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public requestActionOnTopic(requestParameters: RequestActionOnTopicRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public requestActionOnTopic(requestParameters: RequestActionOnTopicRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public requestActionOnTopic(requestParameters: RequestActionOnTopicRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling requestActionOnTopic.');
        }
        const requestType = requestParameters.requestType;
        if (requestType === null || requestType === undefined) {
            throw new Error('Required parameter requestType was null or undefined when calling requestActionOnTopic.');
        }
        const reason = requestParameters.reason;
        if (reason === null || reason === undefined) {
            throw new Error('Required parameter reason was null or undefined when calling requestActionOnTopic.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (reason !== undefined && reason !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reason, 'reason');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/topic/request/create/${encodeURIComponent(String(requestType))}/${encodeURIComponent(String(id))}`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search Topics 
     * Can search for Topics
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTopic(requestParameters: SearchTopicRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsTopicSearchResponsePojo>;
    public searchTopic(requestParameters: SearchTopicRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsTopicSearchResponsePojo>>;
    public searchTopic(requestParameters: SearchTopicRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsTopicSearchResponsePojo>>;
    public searchTopic(requestParameters: SearchTopicRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const dueDate = requestParameters.dueDate;
        const averageMessageSizeInBytes = requestParameters.averageMessageSizeInBytes;
        const description = requestParameters.description;
        const deactivatedBy = requestParameters.deactivatedBy;
        const createdAt = requestParameters.createdAt;
        const reasonForDisapproval = requestParameters.reasonForDisapproval;
        const maximumMessageSizeInBytes = requestParameters.maximumMessageSizeInBytes;
        const retentionSupported = requestParameters.retentionSupported;
        const id = requestParameters.id;
        const requestingSystem = requestParameters.requestingSystem;
        const approvalStatus = requestParameters.approvalStatus;
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const organizationName = requestParameters.organizationName;
        const systemDefinedTopic = requestParameters.systemDefinedTopic;
        const retryIntervalInSeconds = requestParameters.retryIntervalInSeconds;
        const systemEventClass = requestParameters.systemEventClass;
        const maxRetry = requestParameters.maxRetry;
        const topicId = requestParameters.topicId;
        const createdBy = requestParameters.createdBy;
        const clientCode = requestParameters.clientCode;
        const ownedByClientCode = requestParameters.ownedByClientCode;
        const name = requestParameters.name;
        const dateDeactivated = requestParameters.dateDeactivated;
        const topicName = requestParameters.topicName;
        const ownedBy = requestParameters.ownedBy;
        const status = requestParameters.status;
        const isNotApproved = requestParameters.isNotApproved;
        const offset = requestParameters.offset;
        const endDate = requestParameters.endDate;
        const systemConstants = requestParameters.systemConstants;
        const organization = requestParameters.organization;
        const subscribedToByClientId = requestParameters.subscribedToByClientId;
        const limit = requestParameters.limit;
        const startDate = requestParameters.startDate;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (dueDate !== undefined && dueDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dueDate, 'dueDate');
        }
        if (averageMessageSizeInBytes !== undefined && averageMessageSizeInBytes !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>averageMessageSizeInBytes, 'averageMessageSizeInBytes');
        }
        if (description !== undefined && description !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>description, 'description');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (reasonForDisapproval !== undefined && reasonForDisapproval !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reasonForDisapproval, 'reasonForDisapproval');
        }
        if (maximumMessageSizeInBytes !== undefined && maximumMessageSizeInBytes !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>maximumMessageSizeInBytes, 'maximumMessageSizeInBytes');
        }
        if (retentionSupported !== undefined && retentionSupported !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>retentionSupported, 'retentionSupported');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (requestingSystem !== undefined && requestingSystem !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requestingSystem, 'requestingSystem');
        }
        if (approvalStatus !== undefined && approvalStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>approvalStatus, 'approvalStatus');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (organizationName !== undefined && organizationName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>organizationName, 'organizationName');
        }
        if (systemDefinedTopic !== undefined && systemDefinedTopic !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>systemDefinedTopic, 'systemDefinedTopic');
        }
        if (retryIntervalInSeconds !== undefined && retryIntervalInSeconds !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>retryIntervalInSeconds, 'retryIntervalInSeconds');
        }
        if (systemEventClass !== undefined && systemEventClass !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>systemEventClass, 'systemEventClass');
        }
        if (maxRetry !== undefined && maxRetry !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>maxRetry, 'maxRetry');
        }
        if (topicId !== undefined && topicId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>topicId, 'topicId');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (clientCode !== undefined && clientCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientCode, 'clientCode');
        }
        if (ownedByClientCode !== undefined && ownedByClientCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ownedByClientCode, 'ownedBy.clientCode');
        }
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (topicName !== undefined && topicName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>topicName, 'topicName');
        }
        if (ownedBy !== undefined && ownedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ownedBy, 'ownedBy');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (isNotApproved !== undefined && isNotApproved !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isNotApproved, 'isNotApproved');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (endDate !== undefined && endDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>endDate, 'endDate');
        }
        if (systemConstants !== undefined && systemConstants !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>systemConstants, 'systemConstants');
        }
        if (organization !== undefined && organization !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>organization, 'organization');
        }
        if (subscribedToByClientId !== undefined && subscribedToByClientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subscribedToByClientId, 'subscribedToByClientId');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (startDate !== undefined && startDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startDate, 'startDate');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsTopicSearchResponsePojo>(`${this.configuration.basePath}/topic`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search Topics Requests 
     * Can search for Topics Requests
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTopicRequests(requestParameters: SearchTopicRequestsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsTopicRequestSearchResponsePojo>;
    public searchTopicRequests(requestParameters: SearchTopicRequestsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsTopicRequestSearchResponsePojo>>;
    public searchTopicRequests(requestParameters: SearchTopicRequestsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsTopicRequestSearchResponsePojo>>;
    public searchTopicRequests(requestParameters: SearchTopicRequestsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const requestReason = requestParameters.requestReason;
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const subscription = requestParameters.subscription;
        const reviewedBy = requestParameters.reviewedBy;
        const type = requestParameters.type;
        const createdAt = requestParameters.createdAt;
        const reviewReason = requestParameters.reviewReason;
        const createdBy = requestParameters.createdBy;
        const topic = requestParameters.topic;
        const topicName = requestParameters.topicName;
        const topicName2 = requestParameters.topicName2;
        const id = requestParameters.id;
        const user = requestParameters.user;
        const status = requestParameters.status;
        const offset = requestParameters.offset;
        const endDate = requestParameters.endDate;
        const limit = requestParameters.limit;
        const startDate = requestParameters.startDate;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (requestReason !== undefined && requestReason !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requestReason, 'requestReason');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (subscription !== undefined && subscription !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subscription, 'subscription');
        }
        if (reviewedBy !== undefined && reviewedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewedBy, 'reviewedBy');
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (reviewReason !== undefined && reviewReason !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewReason, 'reviewReason');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (topic !== undefined && topic !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>topic, 'topic');
        }
        if (topicName !== undefined && topicName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>topicName, 'topicName');
        }
        if (topicName2 !== undefined && topicName2 !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>topicName2, 'topic.name');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (user !== undefined && user !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>user, 'user');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (endDate !== undefined && endDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>endDate, 'endDate');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (startDate !== undefined && startDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startDate, 'startDate');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsTopicRequestSearchResponsePojo>(`${this.configuration.basePath}/topic/request`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
