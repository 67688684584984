<div class=" mt-3" style="max-width: 2000px;">
  <div class="d-flex flex-wrap flex-md-nowrap">
    <div class="w-auto" style="min-width: 300px; min-height: 100vh;">
      <nav id="api-guide" class="navbar border-end navbar-light flex-column align-items-start p-3"
            style="
                        position: sticky;
                        border-color: #BACCE8 !important;
                        max-height: 100vh;
                        overflow-x: hidden;
                        overflow-y: scroll;
                        top: 0;
                    "
      >
        <nav class="nav flex-column">
          <a class="nav-link" [ngClass]="{'active': fragmentValue == 'intro'}" (click)="setActive('intro')" data-target-id="intro">Introduction</a>
          <a class="nav-link" [ngClass]="{'active': fragmentValue == 'getting-started'}" (click)="setActive('getting-started')" data-target-id="getting-started">Getting Started</a>
          <a class="nav-link" [ngClass]="{'active': fragment == 'obtaining-access'}" (click)="setActive('obtaining-access')" data-target-id="obtaining-access">Obtaining Access</a>
          <a class="nav-link" [ngClass]="{'active': fragment == 'authentication'}" (click)="setActive('authentication')" data-target-id="authentication">Authentication</a>
          <a class="nav-link" [ngClass]="{'active': fragment == 'error-handling'}" (click)="setActive('error-handling')" data-target-id="error-handling">Errors</a>
          <a class="nav-link" [ngClass]="{'active': fragment == 'sdk'}" (click)="setActive('sdk')" data-target-id="sdk">Client Libraries</a>
          <a class="nav-link" [ngClass]="{'active': fragment == 'publisher'}" (click)="setActive('publisher')" data-target-id="publisher">Publisher</a>
            <nav class="nav flex-column">
              <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'get-topics'}" (click)="setActive('get-topics')" data-target-id="get-topics">Get all topics</a>
              <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'create-topics'}" (click)="setActive('create-topics')" data-target-id="create-topics">Create a topic</a>
              <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'post-to-topics'}" (click)="setActive('post-to-topics')" data-target-id="post-to-topics">Post a notification to a topic</a>
              <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'delete-topics'}" (click)="setActive('delete-topics')" data-target-id="delete-topics">Delete a topic</a>
            </nav>
          <a class="nav-link" [ngClass]="{'active': fragment == 'subscriber'}" (click)="setActive('subscriber')" data-target-id="subscriber">Subscriber</a>
            <nav class="nav flex-column">
              <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'get-subs'}" (click)="setActive('get-subs')" data-target-id="get-subs">Get all subscriptions</a>
              <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'create-subs'}" (click)="setActive('create-subs')" data-target-id="create-subs">Subscribe to a topic</a>
              <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'confirm-to-subs'}" (click)="setActive('confirm-to-subs')" data-target-id="confirm-to-subs">Confirm a subscription</a>
              <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'unsub-topics'}" (click)="setActive('unsub-topics')" data-target-id="unsub-topics">Unsubscribe from a topic</a>
            </nav>
          <a class="nav-link" [ngClass]="{'active': fragment == 'tps'}" (click)="setActive('tps')" data-target-id="tps">TPS Integretion Use Cases</a>
          <nav class="nav flex-column">
            <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'billing'}" (click)="setActive('billing')" data-target-id="billing">Billing Use Case</a>
            <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'tokenization'}" (click)="setActive('tokenization')" data-target-id="tokenization">Tokenization Use Case</a>
            <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'abis'}" (click)="setActive('abis')" data-target-id="abis">ABIS Use Case</a>
            <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'population'}" (click)="setActive('population')" data-target-id="population">Population Registry Use Case</a>
          </nav>
        </nav>
      </nav>
    </div>

    <div class="w-100 pe-4" style="overflow-x: hidden;">
        <div class="ms-4 py-3 border-bottom  " style="border-color:#BACCE8 !important; " id="intro">
          <div class=" pe-4">
            <h3  class="mb-3">Introduction</h3>
            <div class="mb-3">The Enterprise Messaging Engine (EME) is the the data integration system for the NIMC ID Ecosystem components. It provides a reliable
              messaging service for data exchange among the Ecosystem components in line with OSIA standards.</div>
            <div class="mb-3">This documentation provides necessary and sufficient information to sucessfully integrate a component to the ID Ecosystem. </div>
            <div class="mb-3">For help or enquiry, contact the NIMC Software Team Lead on <a href="mailto:software@nimc.gov.ng">software@nimc.gov.ng</a>.</div>
          </div>
        </div>
        <div class="border-bottom ms-2 mb-3" style="border-color:#BACCE8 !important; " id="getting-started">
          <div class="ms-2 py-4">
            <div class=" pe-4">
              <h3 class="mb-3">Getting Started</h3>
              <div class="mb-3">To get started, you need to obtain your client credentials from the NIMC Software team. Upon request and approval, you will be provided with test
                credentials for use in implementing and testing your usecases. </div>
              <div class="mb-3">When satisfied with your test integrations, you should request for an EME Integration UAT. Steps on how to do this are detailed below.</div>
            </div>
          </div>
        </div>
        <div class=" ms-2 border-bottom mb-3" style="border-color:#BACCE8 !important; " id="obtaining-access">
        <div class="ms-2 py-4">
          <div class=" pe-4">
            <h3  class="mb-3">Obtaining Access</h3>
            <div class="mb-3">To obtain access credentials for test integration to the EME, send a mail using the details and format described below.
            </div>

            <div class="mb-3">To get started, you need to be obtain your client credentials from the HA-NVS team. These credentials are your <u>clientId</u> and <u>client secret</u>. The credentials will be used to authenticate your request using the oauth flow described below.</div>

            <div class="mb-3">Send a mail to the NIMC Software Team Lead<a href="mailto:software@nimc.gov.ng"> software@nimc.gov.ng</a>:</div>

            <div class="mb-3"><strong>Subject:</strong> NIMC EME Integration - [Name of Your System]</div>

            <div class="mb-3"><strong>Email Body:</strong></div>

            <div class="mb-3">
              <ul>
                <li>
                  Name of Organisation
                </li>
                <li>
                  System(s) you wish to Integrate to the EME:
                </li>
                <li>
                  Key use case(s) or business purpose for the integration:
                </li>
              </ul>
            </div>

            <div class="mb-3"><strong>Developer Contact</strong></div>

            <div class="mb-2">Name:</div>

            <div class="mb-2">Email:</div>

            <div class="mb-2">Phone Number:</div>

            <div class="mb-3">Skype ID:</div>


            <div class="mb-3"><strong>Project Manager Contact</strong></div>


            <div class="mb-2">Name:</div>

            <div class="mb-2">Email:</div>

            <div class="mb-2">Phone Number:</div>

            <div class="mb-3">Skype ID:</div>

            <div class="mb-3">Upon approval, the test credentials would be sent to the contacts provided.</div>
          </div>
        </div>
        </div>
        <div class="border-bottom ms-2  mb-3" style="border-color:#BACCE8 !important; " id="authentication">
        <div class="row ms-2 py-4">
          <div class="col-md-7 pe-4">
            <h3  class="mb-3">Authentication</h3>
            <div class="mb-3">The EME api uses client_id and client_secret based on the <a href="https://oauth.net/2">OAuth2</a> Client Credential Flow for authentication and authorization.</div>
            <div class="mb-3">It also uses the <a href="https://openid.net/connect">OpenID Connect</a> layer on the OAuth2 protocol for identity verification.</div>
            <div class="mb-3">Your client_ids are assigned privileges, so be sure to keep them secure! Do not share your client_id or client_secret in publicly accessible areas such as client-side code.</div>
            <div class="mb-3">All requests to the api must carry the below headers to be properly authenticated.</div>
            <div class="mb-3">
              <ul>
                <li>
                  Authorization (Bearer token)
                </li>
              </ul>
            </div>
            <div class="mb-3">To get your Bearer token, send a post request of your clientId and client secret to the token endpoint. The response will contain your access token. Send this as your bearer token authorization header on all requests. Please note that according to oauth specification and for security reasons, token life spans are short-lived. This means you will need to check if the token is expired first before making a call to the verification endpoints. If the token is expired, call the refresh token endpoint to refresh the token. All the available urls can be gotten from the Discovery URL mentioned below.</div>

            <div class="fs-20 mb-3 mt-4"><strong>Discovery Url</strong></div>

            <div class="mb-3">According to OIDC standard, the discovery url is documented <a href="{{env.keycloakConfig.url +  '/' + 'realms' + '/' + env.keycloakConfig.realm + env.apiDocConfig.discoveryUrlSuffix}}">here</a>. You can find all configurations you need here including the token url mentioned below.</div>

            <div class="fs-20 mb-3 mt-4"><strong>Token Url</strong></div>

            <div class="mb-3 text-link">{{env.apiDocConfig.tokenEndpoint}}</div>

            <div class="fs-20 mb-3 mt-4"><strong>Flow</strong></div>

            <div class="mb-3">Client Credentials flow </div>

            <div class="fs-20 mb-3 mt-4"><strong>Scopes</strong></div>
            <div class="d-flex align-items-center  mb-2">
              <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                notif.topic.write
              </div>
              <div class="ms-2">
                :  Create a new topic. This service is idempotent
              </div>
            </div>

            <div class="d-flex align-items-center mb-2">
              <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                notif.topic.read
              </div>
              <div class="ms-2">
                : List Topics
              </div>
            </div>

            <div class="d-flex align-items-center mb-2">
              <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                notif.topic.publish
              </div>
              <div class="ms-2">
                : Publish Message
              </div>
            </div>

            <div class="d-flex align-items-center mb-2">
              <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                notif.sub.write
              </div>
              <div class="ms-2">
                :  Subscribe to a topic or unsubscribe from a topic or confirm a subscription
              </div>
            </div>

            <div class="d-flex align-items-center mb-4">
              <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                notif.sub.read
              </div>
              <div class="ms-2">
                : List subscriptions
              </div>
            </div>


            <div class="mb-3">
              <ol>
                <li class="mb-2">
                  Your app authenticates with the EME Authorization Server using its Client ID and Client Secret ( /auth/realms/hvas/protocol/openid-connect/token endpoint).
                </li>

                <li class="mb-2">
                  The HVAS Authorization Server validates the Client ID and Client Secret.
                </li>

                <li class="mb-2">
                  The HVAS Authorization Server responds with an Access Token.
                </li>

                <li class="mb-2">
                  Your application can use the Access Token to call an API on behalf of itself.
                </li>

              </ol>
            </div>


            <div class="mb-3">The API responds with requested data. Note that Client credentials flow is not recommended for public clients such as mobile apps and web browsers because of security issues that arise from storing the <span class="px-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important;">client secret</span> on the device.</div>
      </div>
          <div class="col-md-5 ps-5">

            <div class="fs-16 mb-3 "><strong>Sample access token request</strong></div>

            <!-- <div class="mb-3">Find below a sample request to get your access token</div> -->

            <div class="mb-3"><u>Request</u></div>

            <div class="bg-dark text-secondary p-3 mb-4 fs-14">
              <p class="m-0 ">curl --request POST \</p>
              <p class="m-0 ">  --url <span style="color: #99EFA2">$ {{env.keycloakConfig.url}}/realms/{{env.keycloakConfig.realm}}/protocol/openid-connect/token'</span> \</p>
              <p class="m-0">  --header <span style="color: #99EFA2">'content-type: application/x-www-form-urlencoded'</span> \</p>
              <p class="m-0">  --data <span class="text-warning">grant_type=</span>client_credentials \</p>
              <p class="m-0">  --data <span class="text-warning">client_id=</span>YOUR_CLIENT_ID \</p>
              <p class="m-0">  --data <span class="text-warning">client_secret=</span>YOUR_CLIENT_SECRET \</p>
              <p class="m-0">  --data <span class="text-warning">scope=</span>notif.topic.read</p>
            </div>


            <div class="mb-3"><u>Response</u></div>

            <div class="bg-dark text-white p-3 mb-3 fs-14">
              [
              <p class="m-0">"access_token" <span class="text-warning">:</span><span style="color: #99EFA2"> "YOUR_ACCESS_TOKEN"</span></p>
              <p class="m-0">"expires_in" <span class="text-warning">:</span><span style="color: #99EFA2">300</span></p>
              <p class="m-0">"refresh_expires_in" <span class="text-warning">:</span><span style="color: #99EFA2">1800</span></p>
              <p class="m-0">"refresh_token" <span class="text-warning">:</span><span style="color: #99EFA2"> "YOUR_REFRESH_TOKEN"</span></p>
              <p class="m-0">"token_type" <span class="text-warning">:</span><span style="color: #99EFA2"> "bearer"</span></p>
              <p class="m-0">"not-before-policy" <span class="text-warning">:</span><span style="color: #99EFA2"> "1599472100"</span></p>
              <p class="m-0">"session_state" <span class="text-warning">:</span><span style="color: #99EFA2"> "417d00ac-5d99-4ca8-8b5d-385b564f7b56"</span></p>
              <p class="m-0">"scope" <span class="text-warning">:</span><span style="color: #99EFA2"> "pr.person.read"</span></p>
              ]
            </div>

          </div>
        </div>
        </div>
        <div class="border-bottom ms-2 mb-3" style="border-color:#BACCE8 !important; " id="error-handling">
        <div class="row ms-2 py-4">
          <div class="col-md-7 pe-4">
            <h3 class="mt-0">Errors</h3>
            <div class="mb-3">Error handling is something no one wants to talk about however, we take it very seriously. The EME system has a robust and transparent error handling system. This means that whenever something goes wrong for whatever reason, we let you know what happened and why. When something goes wrong, you will get an HTTP status code <span class="px-2  border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">40x</span> or  <span class="px-2  border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">50x</span> and the body will be a json object describing the error, in the format to the right.</div>
            <div class="mb-3">Find below a list of error message codes for the EME</div>

            <div class="mb-3">
              <ul>
                <li class="mb-1">
                  <span class="px-2  border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important;">1000</span> OPERATION NOT SUPPORTED
                </li>
                <li class="mb-1">
                  <span class="px-2  border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important;">1001</span> INVALID PARAMETER
                </li>
                <li class="mb-1">
                  <span class="px-2  border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important;">1101</span> INTERNAL ERROR
                </li>
                <li class="mb-1">
                  <span class="px-2  border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important;">5001</span> SERVICE DISCONNECTED
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-5 ps-5">
            <div class="bg-dark text-white p-3 mb-4 fs-14">
              <p class="m-0 ">json</p>
              <p class="m-0 " style="color: #9B2B64"> [</p>
              <p class="m-0">"code" <span style="color: #9B2B64">:</span> <span style="color: #99EFA2">1000</span>,</p>
              <p class="m-0">  "message" <span style="color: #9B2B64">:</span> <span style="color: #99EFA2">"string"</span> </p>
              <p class="m-0" style="color: #9B2B64"> ]</p>

            </div>
          </div>
        </div>
        </div>
        <div class="border-bottom ms-2 mb-3" style="border-color:#BACCE8 !important; " id="sdk">
        <div class="row ms-2 py-4">
          <div class="col-md-7 pe-4">
            <h3 class="mt-0">Client Libraries</h3>
            <div class="fs-20 mb-3"><strong>Java</strong></div>
            <div class="mb-3">The Java SDK for integration to the EME can be accessed from <a href="#" (click)="getSDKUrl() " class="text-link">here</a></div>
            <!-- <div class="mb-3">Open the <a href="">readme.md</a> file for steps in using the SDK</div> -->
          </div>
          <div class="col-md-5 ps-5">
            <div class="card-white-bg rounded shadow-sm mb-4" >
              <div class="fw-bold border-bottom pb-2 pt-3 px-3">CLIENT LIBRARIES</div>
              <div class="card-body">
                <div class="row gy-3  p-3">
                  <div class="col-2">
                    <a (click)="getSDKUrl() ">
                      <div class="mb-2"><a href=""><span class="iconify" style="width:40px; height:40px;" data-icon="logos:java"></span></a></div>
                      <div class="fs-12 ms-2 ">Java</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="border-bottom ms-2 mb-3" style="border-color:#BACCE8 !important; " id="publisher" >
            <h3 class="mt-5 ms-2">Publisher </h3>
            <div class="row border-bottom mb-4  ms-2" style="border-color:#BACCE8 !important; ">
              <div class="col-md-7 pe-4">
                <h5 id="get-topics" class="mt-3 mb-2">Get all topics</h5>
                <div class="fs-20 mb-3 mt-4"><strong>Scope</strong></div>
                <div class="d-flex align-items-center  mb-2">
                  <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                    notif.topic.publish
                  </div>
                  <div class="ms-2">
                    :  Publish message
                  </div>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="accordion mb-3" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header text-white" id="headingOne" >
                      <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        GET /v1/topics

                        <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                        <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body fs-14">
                        https://msg.nimc.gov.ng/api/v1/topics
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rounded shadow-sm mb-4 " >
                  <div class="py-2 px-3 " style="background-color: #3C4257;">
                    <div class="fw-bold mb-3 mt-2 text-white">
                      RESPONSE SAMPLES
                    </div>
                    <div class="row gy-4">
                      <div class="col-md-3">
                        <button class="btn btn-light text-success" (click)="switchGetAllTopicsResponses(200)" >200</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchGetAllTopicsResponses(500)">500</button>
                      </div>
                    </div>
                  </div>
                  <div class="p-3 g-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                    <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                      <div>Content type</div>
                      <div>application/json</div>
                    </div>
                    <div class="text-end"><button class="btn-unset-all" (click)="copyCode(get_all_topics)">Copy</button></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14">
                      <pre><code>{{get_all_topics}}</code></pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row border-bottom mb-4 ms-2" style="border-color:#BACCE8 !important; ">
              <div class="col-md-7 pe-4">
                <h5 id="create-topics" class="mt-3 mb-2">Create a topic</h5>
                <div class="fs-20 mb-3 mt-4"><strong>Scope</strong></div>
                <div class="d-flex align-items-center  mb-2">
                  <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                    notif.topic.write
                  </div>
                  <div class="ms-2">
                    :  Create a new topic. This service is idempotent
                  </div>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="accordion mb-3" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header text-white" id="headingOne" >
                      <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        POST /v1/topics

                        <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                        <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body fs-14">
                       https://msg.nimc.gov.ng/api/v1/topics
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rounded shadow-sm mb-4 " >
                  <div class="py-2 px-3 " style="background-color: #3C4257;">
                    <div class="fw-bold mb-3 mt-2 text-white">
                      RESPONSE SAMPLES
                    </div>
                    <div class="row gy-4">
                      <div class="col-md-3">
                        <button class="btn btn-light text-success" (click)="switchGetAllTopicsResponses(200)">200</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchGetAllTopicsResponses(400)">400</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchGetAllTopicsResponses(500)">500</button>
                      </div>
                    </div>
                  </div>
                  <div class="p-3 g-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                    <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                      <div>Content type</div>
                      <div>application/json</div>
                    </div>
                    <div class="text-end"><button class="btn-unset-all" (click)="copyCode(get_all_topics)">Copy</button></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14">
                      <pre><code>{{get_all_topics}}</code></pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row border-bottom mb-4 ms-2" style="border-color:#BACCE8 !important; ">
              <div class="col-md-7 pe-4">
                <h5 id="post-to-topics" class="mt-3 mb-2">Post a notification topic</h5>
                <div class="fs-20 mb-3 mt-4"><strong>Scope</strong></div>
                <div class="d-flex align-items-center  mb-2">
                  <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                    notif.topic.write
                  </div>
                  <div class="ms-2">
                    :  Create a new topic. This service is idempotent
                  </div>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="accordion mb-3" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header text-white" id="headingOne" >
                      <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {{"POST / v1 / topics / {uuid} / publish"}}

                        <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                        <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body fs-14">
                        {{"https://msg.nimc.gov.ng/api/v1/topics/{uuid}/publish"}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rounded shadow-sm mb-4 " >
                  <div class="py-2 px-3 " style="background-color: #3C4257;">
                    <div class="fw-bold mb-3 mt-2 text-white">
                      RESPONSE SAMPLES
                    </div>
                    <div class="row gy-4">
                      <div class="col-md-3">
                        <button class="btn btn-light text-success" (click)="switchPostTopicsResponses(200)">200</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchPostTopicsResponses(400)">400</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger"(click)="switchPostTopicsResponses(500)">500</button>
                      </div>
                    </div>
                  </div>
                  <div class="p-3 g-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                    <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                      <div>Content type</div>
                      <div>application/json</div>
                    </div>
                    <div class="text-end"><button class="btn-unset-all" (click)="copyCode(post_topics)">Copy</button></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14">
                      <pre><code>{{post_topics}}</code></pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row  mb-4 ms-2" >
              <div class="col-md-7 pe-4">
                <h5 id="delete-topics" class="mt-3 mb-2">Delete a topic</h5>
                <div class="fs-20 mb-3 mt-4"><strong>Scope</strong></div>
                <div class="d-flex align-items-center  mb-2">
                  <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                    notif.topic.write
                  </div>
                  <div class="ms-2">
                    :  Delete a topic. This service is idempotent
                  </div>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="accordion mb-3" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header text-white" id="headingOne" >
                      <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {{"DELETE / v1 / topics / {uuid}"}}

                        <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                        <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body fs-14">
                        {{"https://msg.nimc.gov.ng/api/v1/topics/{uuid}"}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rounded shadow-sm mb-4 " >
                  <div class="py-2 px-3 " style="background-color: #3C4257;">
                    <div class="fw-bold mb-3 mt-2 text-white">
                      RESPONSE SAMPLES
                    </div>
                    <div class="row gy-4">
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchDeleteTopicsResponses(400)" >400</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchDeleteTopicsResponses(500)" >500</button>
                      </div>
                    </div>
                  </div>
                  <div class="p-3 g-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                    <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                      <div>Content type</div>
                      <div>application/json</div>
                    </div>
                    <div class="text-end"><button class="btn-unset-all" (click)="copyCode(bad_response)">Copy</button></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14">
                      <pre><code>{{bad_response}}</code></pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="border-bottom ms-2 mb-3" style="border-color:#BACCE8 !important; " id="subscriber" >
          <h3 class="mt-5 ms-2">Subscriber </h3>
          <div class="row border-bottom mb-4  ms-2" style="border-color:#BACCE8 !important; ">
            <div class="col-md-7 pe-4">
              <h5 id="get-subs" class="mt-3 mb-2">Get all subscriptions</h5>
              <div class="fs-20 mb-3 mt-4"><strong>Scope</strong></div>
              <div class="d-flex align-items-center  mb-2">
                <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                  notif.sub.write
                </div>
                <div class="ms-2">
                  :  Get all active subscriptions
                </div>
              </div>
            </div>
            <div class="col-md-5 ps-5">
              <div class="accordion mb-3" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header text-white" id="headingOne" >
                    <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      /v1/subscriptions

                      <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                      <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body fs-14">
                      https://msg.nimc.gov.ng/api/v1/subscriptions
                    </div>
                  </div>
                </div>
              </div>
              <div class="rounded shadow-sm mb-4 " >
                <div class="py-2 px-3 " style="background-color: #3C4257;">
                  <div class="fw-bold mb-3 mt-2 text-white">
                    RESPONSE SAMPLES
                  </div>
                  <div class="row gy-4">
                    <div class="col-md-3">
                      <button class="btn btn-light text-success" (click)="switchGetAllSubscriptionsResponses(200)">200</button>
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-dark text-danger" (click)="switchGetAllSubscriptionsResponses(500)">500</button>
                    </div>
                  </div>
                </div>
                <div class="p-3 g-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                  <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                    <div>Content type</div>
                    <div>application/json</div>
                  </div>
                  <div class="text-end"><button class="btn-unset-all" (click)="copyCode(get_all_subscriptions)">Copy</button></div>
                  <div class="bg-dark text-white p-3 mb-4 fs-14">
                    <pre><code>{{get_all_subscriptions}}</code></pre>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row border-bottom mb-4 ms-2" style="border-color:#BACCE8 !important; ">
            <div class="col-md-7 pe-4">
              <h5 id="create-subs" class="mt-3 mb-2">Subscribe to a topic</h5>
              <div class="fs-20 mb-3 mt-4"><strong>Scope</strong></div>
              <div class="d-flex align-items-center  mb-2">
                <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                  notif.sub.write
                </div>
                <div class="ms-2">
                  :  Subscribe to a topic
                </div>
              </div>
            </div>
            <div class="col-md-5 ps-5">
              <div class="accordion mb-3" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header text-white" id="headingOne" >
                    <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      /v1/subscriptions

                      <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                      <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body fs-14">
                      https://msg.nimc.gov.ng/api/v1/subscriptions
                    </div>
                  </div>
                </div>
              </div>
              <div class="rounded shadow-sm mb-4 " >
                <div class="py-2 px-3 " style="background-color: #3C4257;">
                  <div class="fw-bold mb-3 mt-2 text-white">
                    RESPONSE SAMPLES
                  </div>
                  <div class="row gy-4">
                    <div class="col-md-3">
                      <button class="btn btn-light text-success" (click)="switchGetAllSubscriptionsResponses(200)">200</button>
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-dark text-danger" (click)="switchGetAllSubscriptionsResponses(400)">400</button>
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-dark text-danger" (click)="switchGetAllSubscriptionsResponses(500)">500</button>
                    </div>
                  </div>
                </div>
                <div class="p-3 g-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                  <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                    <div>Content type</div>
                    <div>application/json</div>
                  </div>
                  <div class="text-end"><button class="btn-unset-all" (click)="copyCode(get_all_subscriptions)">Copy</button></div>
                  <div class="bg-dark text-white p-3 mb-4 fs-14">
                    <pre><code>{{get_all_subscriptions}}</code></pre>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row border-bottom mb-4 ms-2" style="border-color:#BACCE8 !important; ">
            <div class="col-md-7 pe-4">
              <h5 id="confirm-to-subs" class="mt-3 mb-2">Confirm the subscription</h5>
              <div class="fs-20 mb-3 mt-4"><strong>Scope</strong></div>
              <div class="d-flex align-items-center  mb-2">
                <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                  notif.sub.write
                </div>
                <div class="ms-2">
                  :  Confirm subscription to a topic
                </div>
              </div>
            </div>
            <div class="col-md-5 ps-5">
              <div class="accordion mb-3" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header text-white" id="headingOne" >
                    <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      /v1/subscriptions/confirm

                      <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                      <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body fs-14">
                      https://msg.nimc.gov.ng/api/v1/subscriptions/confirm
                    </div>
                  </div>
                </div>
              </div>
              <div class="rounded shadow-sm mb-4 " >
                <div class="py-2 px-3 " style="background-color: #3C4257;">
                  <div class="fw-bold mb-3 mt-2 text-white">
                    RESPONSE SAMPLES
                  </div>
                  <div class="row gy-4">

                    <div class="col-md-3">
                      <button class="btn btn-dark text-danger" (click)="switchDeleteTopicsResponses(400)">400</button>
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-dark text-danger" (click)="switchDeleteTopicsResponses(500)">500</button>
                    </div>
                  </div>
                </div>
                <div class="p-3 g-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                  <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                    <div>Content type</div>
                    <div>application/json</div>
                  </div>
                  <div class="text-end"><button class="btn-unset-all" (click)="copyCode(bad_response)">Copy</button></div>
                  <div class="bg-dark text-white p-3 mb-4 fs-14">
                    <pre><code>{{bad_response}}</code></pre>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row  mb-4 ms-2" >
            <div class="col-md-7 pe-4">
                  <h5 id="unsub-topics" class="mt-3 mb-2">Unsubscribe from a topic</h5>
                  <div class="fs-20 mb-3 mt-4"><strong>Scope</strong></div>
                  <div class="d-flex align-items-center  mb-2">
                  <div class="py-1 ps-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important; width: 140px;">
                      notif.sub.write
                  </div>
                  <div class="ms-2">
                      : Unsubscribe from a topic
                  </div>
                  </div>
              </div>


            <div class="col-md-5 ps-5">
              <div class="accordion mb-3" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header text-white" id="headingOne" >
                    <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      /v1/subscriptions

                      <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                      <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body fs-14">
                      https://msg.nimc.gov.ng/api/v1/subscriptions
                    </div>
                  </div>
                </div>
              </div>
              <div class="rounded shadow-sm mb-4 " >
                <div class="py-2 px-3 " style="background-color: #3C4257;">
                  <div class="fw-bold mb-3 mt-2 text-white">
                    RESPONSE SAMPLES
                  </div>
                  <div class="row gy-4">
                    <div class="col-md-3">
                      <button class="btn btn-dark text-danger" (click)="switchDeleteTopicsResponses(400)">400 </button>
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-dark text-danger" (click)="switchDeleteTopicsResponses(500)">500</button>
                    </div>
                  </div>
                </div>
                <div class="p-3 g-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                  <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                    <div>Content type</div>
                    <div>application/json</div>
                  </div>
                  <div class="text-end"><button class="btn-unset-all" (click)="copyCode(bad_response)">Copy</button></div>
                  <div class="bg-dark text-white p-3 mb-4 fs-14">
                    <pre><code>{{bad_response}}</code></pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
        <div class=" ms-2 mb-3 ps-2" id="tps">
          <h3 class=" pt-2 pb-3 mt-4">TPS Integration Use Cases</h3>
          <h4 id="billing" class="mb-3 ">Billing Use Case</h4>
          <div class="mb-3 ">One of the core values of the EME in the ID Ecosystem is to allow for financial integration of different subsystems within the ID Ecosystem.</div>
          <div class="mb-3 ">The following summarizes the essence of this financial integration:</div>
          <div class="mb-3 ">
            <ul>
              <li class="mb-2">
                <div>All services' transactions provided by any third party system (TPS) on behalf of NIMC must be anchored on a customer account and on a service item. The list of customer accounts and service items are maintained by the NIMS Convergent Billing System (CBS) and are available to all TPS by subscribing to the appropriate topics on EME.</div>
              </li>
              <li class="mb-2">
                <div>When a TPS renders a service, it is to notify CBS, through the EME, of the service rendered including the information of the service item, customer account and amount paid for the service. The TPS must subscribe to this fulfillment topic and update their records with the corresponding blockchain reference. </div>
              </li>
            </ul>
          </div>
          <div class="mb-3 ">Audit processes by NIMC on TPS will include checking that all transactions on the TPS system have the corresponding blockchain reference from CBS</div>

          <div class="ps-3">
            <h5 id="topics-format" class="mt-5 text-header mb-3 fw-bold fs-20">Topics and Message Format</h5>
            <div class="row border-bottom mb-5 pb-3 " style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div id="topics-services" class="mb-3 mt-2 text-header fw-bold fs-18">Services</div>
                <div class="mb-3 ">All TPS systems are to subscribe to these topics</div>
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong > billing.service.new</strong> - All new services are published here
                  </div>
                  <div>
                    <strong> billing.service.update</strong> - Updates to any existing service is published here
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>serviceName</td>
                        <td>String</td>
                        <td>1-40 characters</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th scope="row">serviceCode</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th scope="row">serviceItem</th>
                        <td>Array</td>
                        <td>1 or more array elements</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          -
                      </tr>

                      <tr>
                        <th scope="row">status</th>
                        <td>String</td>
                        <td>active | inactive</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{sample_message}}</code></pre>
                  </div>
              </div>
            </div>

            <div class="row border-bottom mb-5 pb-3" style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div id="topics-services" class="mb-3 mt-2 text-header fw-bold fs-18">Customers</div>
                <div class="mb-3 ">All TPS systems are to subscribe to these topics</div>
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >customer.identity.new</strong> - All customers and their accounts are published here
                  </div>
                  <div>
                    <strong> customer.identity.update</strong> - Updates to any existing customer is published here
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>customerName</td>
                        <td>String</td>
                        <td>1-70 characters</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>customerId</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>account (code, jurisdiction, status)</td>
                        <td>Array</td>
                        <td>1 or more array elements</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>phoneNumber</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          -
                        </td>
                      </tr>

                      <tr>
                        <td>email</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>customerType</td>
                        <td>String</td>
                        <td>individual | organization</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>organisationType</td>
                        <td>String</td>
                        <td>corporate | mda | ngo | international_organisation</td>
                        <td>-</td>
                        <td>Conditional</td>
                        <td>Required if customer type is organization</td>
                      </tr>

                      <tr>
                        <td>registrationNumber</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>sector</td>
                        <td>String</td>
                        <td>private | public</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>streetAddress</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>addressLine2</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>postalCode</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>townOrCity</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>state</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>country</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>status</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{sample_message_2}}</code></pre>
                  </div>
              </div>

            </div>

            <div class="row border-bottom mb-5 pb-3" style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div id="topics-services" class="mb-3 mt-2 text-header fw-bold fs-18">Service Transaction Notification</div>
                <div class="mb-3 ">TPS systems are to publish to the topics corresponding to their system</div>
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >billing.mws.transaction.notify</strong> - The MWS TPS publishes service transactions to this topic
                  </div>
                  <div class="mb-2">
                    <strong>billing.tokenization.transaction.notify</strong> - The Tokenization Service TPS publishes service transactions to this topic here
                  </div>
                  <div class="mb-2">
                    <strong>billing.nvs.transaction.notify</strong> - The NVS TPS publishes service transactions to this topic
                  </div>
                  <div>
                    <strong>billing.hclone.transaction.notify</strong> - The HClone TPS publishes service transactions to this topic
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>serviceItemName</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>Service item name gotten from CBS</td>
                      </tr>

                      <tr>
                        <td>serviceItemCode</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>Service item code gotten from CBS</td>
                      </tr>

                      <tr>
                        <td>customerAccountCode</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>Customer account code gotten from CBS</td>
                      </tr>

                      <tr>
                        <td>tpsServiceTransactionId</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          Unique transaction id for the service being notified
                        </td>
                      </tr>

                      <tr>
                        <td>reasonForService</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>tpsSystemCode</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>mws | hclone | nvs | tokenization</td>
                      </tr>


                      <tr>
                        <td>tpsTransactionTime</td>
                        <td>String</td>
                        <td>
                          yyyy-MM-dd HH:mm:ss.SSS
                          </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>tpsTransactionType</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>nin</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Conditional</td>
                        <td>Required if transaction type requires NIN</td>
                      </tr>

                      <tr>
                        <td>ninToken</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Conditional</td>
                        <td>Required if transaction type requires Token</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{sample_message_3}}</code></pre>
                  </div>
              </div>

            </div>

            <div class="row border-bottom mb-5 pb-3" style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div id="topics-services" class="mb-3 mt-2 text-header fw-bold fs-18">Service Transaction Notification Error Response</div>

                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >billing.mws.transaction.notify.error</strong> - The MWS TPS subscribes to this
                  </div>
                  <div class="mb-2">
                    <strong>billing.tokenization.transaction.notify.error</strong> - The Tokenization Service TPS subscribes to this topic
                  </div>
                  <div class="mb-2">
                    <strong>billing.nvs.transaction.notify.error</strong> - The NVS TPS subscribes to this topic
                  </div>
                  <div>
                    <strong>billing.hclone.transaction.notify.error</strong> - The HClone TPS subscribes to this topic
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>code</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>Error code</td>
                      </tr>

                      <tr>
                        <td>message</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>Error message</td>
                      </tr>

                      <tr>
                        <td>tpsServiceTransactionId</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{sample_message_4}}</code></pre>
                  </div>
              </div>

            </div>

            <div class="row border-bottom mb-5 pb-3" style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div id="topics-services" class="mb-3 mt-2 text-header fw-bold fs-18">Service Fulfillment</div>
                <div class="mb-3 ">TPS systems are to subscribe to the topics corresponding to their system</div>
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >billing.mws.transaction.fulfillment</strong> - The MWS TPS subscribes to this topic
                  </div>
                  <div class="mb-2">
                    <strong>billing.tokenization.transaction.fulfillment</strong> - The Tokenization Service TPS subscribes to this topic
                  </div>
                  <div class="mb-2">
                    <strong>billing.nvs.transaction.fulfillment</strong> - The NVS TPS subscribes to this topic
                  </div>
                  <div>
                    <strong>billing.hclone.transaction.fulfillment</strong> - The HClone TPSsubscribes to this topic
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>customerAccountCode</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>Customer account code gotten from CBS</td>
                      </tr>

                      <tr>
                        <td>tpsServiceTransactionId</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>Unique transaction id for the service transaction that was notified</td>
                      </tr>

                      <tr>
                        <td>billingBlockchainReference</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>Unique blockchain reference for the notified transaction, confirming it's been acknowledged by the billing system</td>
                      </tr>

                      <tr>
                        <td>billingFulfillmentTime</td>
                        <td>String</td>
                        <td>yyyy-MM-dd HH:mm:ss.SSS</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{sample_message_5}}</code></pre>
                  </div>
              </div>

            </div>

            <div class="row border-bottom mb-5 pb-3" style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div id="topics-services" class="mb-3 mt-2 text-header fw-bold fs-18">Service Severance</div>
                <div class="mb-3 ">Authorized users from the appropriate NIMC unit may determine that one or more customer accounts need to be cut off from receiving electronic services from a NIMC system providing such service. When this happens, the severance notice will be published to appropriate topics for the relevant systems. These systems are to subscribe to the severance topics corresponding to their system, and publish a confirmation that they have effected the severance</div>
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >billing.mws.account.severe</strong> - The MWS TPS subscribes to this topic
                  </div>
                  <div class="mb-2">
                    <strong>billing.tokenization.account.severe</strong> - The Tokenization Service TPS subscribes to this topic
                  </div>
                  <div class="mb-2">
                    <strong>billing.nvs.account.severe</strong> - The NVS TPS subscribes to this topic
                  </div>
                  <div>
                    <strong>billing.hclone.account.severe</strong> - The HClone TPS subscribes to this topic
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>customerId</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>customerAccountCode</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>reason</td>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Conditional</td>
                        <td>Required if requestType is <strong>severe</strong></td>
                      </tr>

                      <tr>
                        <td>billingRequestId</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          -
                        </td>
                      </tr>

                      <tr>
                        <td>billingRequestTime</td>
                        <td>String</td>
                        <td>yyyy-MM-dd HH:mm:ss.SSS</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          -
                        </td>
                      </tr>

                      <tr>
                        <td>billingRequestType</td>
                        <td>String</td>
                        <td>severe | restore</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{sample_message_6}}</code></pre>
                  </div>
              </div>
            </div>

            <div class="row border-bottom mb-5 pb-3" style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div id="topics-services" class="mb-3 mt-2 text-header fw-bold fs-18">Service Severance Confirmation</div>

                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >billing.mws.account.severe.confirm</strong> - The MWS TPS publishes severance confirmation to this topic
                  </div>
                  <div class="mb-2">
                    <strong>billing.tokenization.account.severe.confirm</strong> - The Tokenization TPS publishes severance confirmation to this topic
                  </div>
                  <div class="mb-2">
                    <strong>billing.nvs.account.severe.confirm</strong> - The NVS TPS publishes severance confirmation to this topic
                  </div>
                  <div>
                    <strong>billing.hclone.account.severe.confirm</strong> -  The HClone TPS publishes severance confirmation to this topic
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>billingRequestId</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>customerAccountCode</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <td>tpsSystemCode</td>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>mws | hclone | nvs | tokenization</td>
                      </tr>

                      <tr>
                        <td>requestCompletionTime</td>
                        <td>String</td>
                        <td>yyyy-MM-dd HH:mm:ss.SSS</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>Time when the request was applied to the customer account on the TPS</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{sample_message_7}}</code></pre>
                  </div>
              </div>

            </div>
          </div>

          <div class="ps-3 border-bottom mb-4" style="border-color:#BACCE8 !important; " >
            <h5 id="use-case" class="mt-5 text-header mb-3 fw-bold fs-20">Use Case Flow</h5>
            <div id="tps" class="mb-3 mt-2 text-header fw-bold fs-18">As a TPS (Not CBS)</div>
            <ul class="mb-5">
              <li class="mb-2">
                Subscribe to the customers topics (customer.identity.new | customer.identity.update ) to have an updated list of all customers and their accounts
              </li>
              <li class="mb-2">
                Ensure all customers on your system derive their customer and account ids from CBS
              </li>
              <li class="mb-2">
                Subscribe to the services topics ( billing.service.new | billing.service.update ) to have an updated list of all services
              </li>
              <li class="mb-2">
                Ensure all services on your system are anchored on (mapped to) the services from CBS as these would be used for notifying CBS of service transactions
              </li>
              <li class="mb-2">
                When services are provided to customers on your system, notify CBS of these service transactions by publishing to the corresponding EME topics for your system
              </li>
              <li class="mb-2">
                CBS responds to service transaction notifications by generating a unique blockchain reference for each transaction notification and sending back to a fulfillment topic
              </li>
              <li class="mb-2">
                Subscribe to the corresponding fulfillment topic for your system and receive the blockchain reference for the corresponding transactions. Update this on your system for future audit purposes
              </li>
            </ul>

            <div id="cbs" class="mb-3 mt-2 text-header fw-bold fs-18">As the CBS</div>
            <ul class="mb-3">
              <li class="mb-2">
                Subscribe to all service transaction notification topics
              </li>
              <li class="mb-2">
                Generate blockchain references for them and publish back to the corresponding fulfillment topic for the service transaction notification
              </li>
              <li class="mb-2">
                Make the transaction information available for use in generating invoices for customers
              </li>
            </ul>
          </div>

          <div>
            <h4 id="tokenization" class="mb-3 mt-5 ">Tokenization Use Case</h4>
            <div class="mb-3 ">One of the TPS in the ID Ecosystem is the Tokenization service. This service is responsible for generating NIN tokens for use in verification by a relying party.</div>
            <div class="mb-3 ">The following events should be notified to the EME for use by other subsystems:</div>
            <div class="mb-3 ">
              <ul>
                <li class="mb-2">
                  <div>New token generation: When a new VNIN is created, this event should be notified to interested systems via the EME. Relevant information include the VNIN, its corresponding NIN, the relying party the token was generated for and the expiry time of the token</div>
                </li>
                <li class="mb-2">
                  <div>Token consumption: When a relying party system utilizes a VNIN for verification, it should publish an event to EME that the token has been consumed. Any interested subsystem will subscribe to this topic and take appropriate action.</div>
                </li>
              </ul>
            </div>
          </div>

          <div class="ps-3">
            <h5 id="topics-format" class="mt-5 text-header mb-3 fw-bold fs-20">Topics and Message Format</h5>
            <div class=" mb-3 pb-3 row " >
              <div class="col-md-7">
                <div id="#" class="mb-3 mt-2 text-header fw-bold fs-18">New Token Generation (VNIN)</div>
                <div class="mb-3 ">TPS systems that handle token for relying party systems are to subscribe to this topic
                </div>
                <div class="mb-4 ">
                  <div class="mb-3">
                    <strong >tps.tokenization.vnin.new</strong> - This event is emitted upon the generation of a new token (VNIN)
                  </div>
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Field Name</th>
                          <th scope="col">Field Type</th>
                          <th scope="col">Restrictions</th>
                          <th scope="col">Format Validation</th>
                          <th scope="col">Required / Optional / Conditional</th>
                          <th scope="col">Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>token</td>
                          <td>String</td>
                          <td>16 digits</td>
                          <td>Not Blank</td>
                          <td>Required</td>
                          <td>-</td>
                        </tr>

                        <tr>
                          <td>uin</td>
                          <td>String</td>
                          <td>11 digits</td>
                          <td>Not Blank</td>
                          <td>Required</td>
                          <td>-</td>
                        </tr>

                        <tr>
                          <td>customerAccountCode</td>
                          <td>String</td>
                          <td>-</td>
                          <td>Not Blank</td>
                          <td>Required</td>
                          <td>-</td>
                        </tr>

                        <tr>
                          <td>timestamp</td>
                          <td>String</td>
                          <td>yyyy-MM-dd HH:mm:ss.SSS</td>
                          <td>Not Blank</td>
                          <td>Required</td>
                          <td>Time when the token was generated</td>
                        </tr>

                        <tr>
                          <th>expiryDate</th>
                          <td>String</td>
                          <td>yyyy-MM-dd HH:mm:ss.SSS</td>
                          <td>Not Blank</td>
                          <td>Required</td>
                          <td>	Time when the token should expire</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{new_vnin_sample}}</code></pre>
                  </div>
              </div>
            </div>

            <div class="pb-3 border-bottom" style="border-color:#BACCE8 !important; ">
              <div class="row">
                <div class="col-md-7">
                  <div id="#" class="my-3 text-header fw-bold fs-18">Token Consumption (VNIN)</div>
                  <div class="mb-3 ">TPS systems that use the tokenization service are to subscribe to this topic to know when other subsystems have consumed a token
                  </div>
                  <div class="mb-3">
                    <div class="mb-3">
                      <strong >tps.tokenization.vnin.consume</strong> - This event is emitted upon usage or consumption of a token (VNIN)
                    </div>
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Field Name</th>
                            <th scope="col">Field Type</th>
                            <th scope="col">Restrictions</th>
                            <th scope="col">Format Validation</th>
                            <th scope="col">Required / Optional / Conditional</th>
                            <th scope="col">Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>token</td>
                            <td>String</td>
                            <td>16 digits</td>
                            <td>Not Blank</td>
                            <td>Required</td>
                            <td>-</td>
                          </tr>

                          <tr>
                            <td>consumedAt</td>
                            <td>String</td>
                            <td>yyyy-MM-dd HH:mm:ss.SSS</td>
                            <td>Not Blank</td>
                            <td>Required</td>
                            <td>Time when the token was consumed</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pb-3 border-bottom" style="border-color:#BACCE8 !important; " >
            <h4 id="abis" class="mb-4 mt-5 ">ABIS Use Case</h4>
            <h5 id="finger-abis" class="text-header mb-3 fw-bold fs-20">Fingerprint</h5>
            <div class="row border-bottom mb-5 pb-3 " style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >biometrics.fingerprint.verify</strong> - This event is emitted by any TPS that seeks to verify fingerprint data
                  </div>
                  <div class="mb-2">
                    <strong >biometrics.fingerprint.verify-error</strong> - This event is emitted when there is an error in processing the message from the original topic
                  </div>
                  <div>
                    <strong>biometrics.fingerprint.verify-response</strong> - This event is emitted when ABIS has processed a biometric fingerprint request
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>biometricType</td>
                        <td>String</td>
                        <td>FINGER</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >biometricSubType</th>
                        <td>String</td>
                        <td>RIGHT_THUMB, RIGHT_INDEX, RIGHT_MIDDLE, RIGHT_RING, RIGHT_LITTLE, LEFT_THUMB, LEFT_INDEX, LEFT_MIDDLE, LEFT_RING,
                          LEFT_LITTLE
                        </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>At least one of the restrictions.</td>
                      </tr>

                      <tr>
                        <th >image</th>
                        <td>String</td>
                        <td>WSQ BASE64-encoded image</td>
                        <td>-</td>
                        <td>Conditional</td>
                        <td>
                          -</td>
                      </tr>

                      <tr>
                        <th >compresion</th>
                        <td>String</td>
                        <td>WSQ</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >quality</th>
                        <td>String</td>
                        <td>Quality, as a number, as a biometric</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >qualityformat</th>
                        <td>String</td>
                        <td>SO_19794, NFIQ, or NFIQ2</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 " ><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 long-div fs-14">
                  <pre><code>{{finger_sample_message}}</code></pre>
                  </div>
              </div>
            </div>

            <h5 id="face-abis" class="text-header mb-3 fw-bold fs-20">Face</h5>
            <div class="row  mb-5 pb-3 ">
              <div class="col-md-7">
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >biometrics.face.verify</strong> - This event is emitted by any TPS that seeks to verify face data
                  </div>
                  <div class="mb-2">
                    <strong >biometrics.face.match</strong> - This event is emitted when ABIS has processed a biometric face request.
                  </div>
                  <div>
                    <strong>biometrics.face.verify-response</strong> - This event is emitted when there is an error in processing the message from the original topic
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>biometricType</td>
                        <td>String</td>
                        <td>FACE</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >biometricSubType</th>
                        <td>String</td>
                        <td>PORTRAIT
                        </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >image</th>
                        <td>String</td>
                        <td>BASE64-encoded image</td>
                        <td>Not Blank</td>
                        <td>Conditional</td>
                        <td>
                          -</td>
                      </tr>

                      <tr>
                        <th >compresion</th>
                        <td>String</td>
                        <td>PNG, JPEG</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 long-div mb-4 fs-14">
                  <pre><code>{{face_sample_message}}</code></pre>
                  </div>
              </div>
            </div>
          </div>

          <div class="" >
            <h4 id="population" class="mb-4 mt-5 ">Population Registry Use Case</h4>
            <h5 id="" class="text-header mb-3 fw-bold fs-20">New Person Record Event</h5>
            <div class="row border-bottom mb-5 pb-3 " style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >pr.person.new</strong> - This event is emitted by any TPS that seeks to verify fingerprint data
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>identityStatus</th>
                        <td>String</td>
                        <td>CLAIMED,
                          VALID,
                          INVALID,
                          REVOKED,
                          SUSPENDED;
                          </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >uin</th>
                        <td>String</td>
                        <td>11 digits
                        </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >identityTransactionId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          -</td>
                      </tr>

                      <tr>
                        <th>title</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>enrolmentTrackingId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>firstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>lastName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>middleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>otherName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>maidenName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousFirstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousLastName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousMiddleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>dateOfBirth</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>gender</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>maritalStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>spouseNin</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>height</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>religion</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>emailAddress</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>telephoneNumber</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nativeSpokenLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>otherSpokenLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>readwriteLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>educationLevel</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>profession</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>employmentStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nationality</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>personId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>enrolmentDate</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>biometricDataList</th>
                        <td>Lit of Biometric Data:
                          -Type
                          -referencePath
                        </td>
                        <td>FACE,
                          FINGERPRINT,
                          IRIS,
                          VOICE,
                          SIGNATURE;
                          </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>identityId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>reasonForIdentityStatusChange</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinFirstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinSurname</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinMiddleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinRelation</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinAddressLine1</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinAddressLine2</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinTown</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinPostCode</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originPlace</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>ResidenceAddress1</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>ResidenceAddress2</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceTown</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>operatorUserId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>physicalStatusVerification</th>
                        <td>String</td>
                        <td>ALIVE, DEAD</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 " ><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{new_person_sample_message}}</code></pre>
                  </div>
              </div>
            </div>

            <h5 id="" class="text-header mb-3 fw-bold fs-20">Update Person Record Event</h5>
            <div class="row border-bottom mb-5 pb-3 " style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >pr.person.update</strong> - This event is emitted upon the Update of an existing Person in the Population registry.
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>identityStatus</th>
                        <td>String</td>
                        <td>CLAIMED,
                          VALID,
                          INVALID,
                          REVOKED,
                          SUSPENDED;
                          </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >uin</th>
                        <td>String</td>
                        <td>11 digits
                        </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >identityTransactionId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          -</td>
                      </tr>

                      <tr>
                        <th>title</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>enrolmentTrackingId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>firstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>lastName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>middleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>otherName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>maidenName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousFirstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousLastName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousMiddleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>dateOfBirth</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>gender</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>maritalStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>spouseNin</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>height</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>religion</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>emailAddress</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>telephoneNumber</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nativeSpokenLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>otherSpokenLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>readwriteLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>educationLevel</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>profession</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>employmentStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nationality</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>personId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>enrolmentDate</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>biometricDataList</th>
                        <td>Lit of Biometric Data:
                          -Type
                          -referencePath
                        </td>
                        <td>FACE,
                          FINGERPRINT,
                          IRIS,
                          VOICE,
                          SIGNATURE;
                          </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>identityId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>reasonForIdentityStatusChange</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinFirstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinSurname</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinMiddleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinRelation</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinAddressLine1</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinAddressLine2</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinTown</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinPostCode</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originPlace</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>ResidenceAddress1</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>ResidenceAddress2</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceTown</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>operatorUserId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>physicalStatusVerification</th>
                        <td>String</td>
                        <td>ALIVE, DEAD</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 " ><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{new_person_sample_message}}</code></pre>
                  </div>
              </div>
            </div>


            <h5 id="" class="text-header mb-3 fw-bold fs-20">Death Report Event</h5>
            <div class="row border-bottom mb-5 pb-3 " style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >enrollment.meta-data.death</strong> - This event is emitted upon the submission of the application for reporting of Death or when person becomes older than the maximum age threshold
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>identityStatus</th>
                        <td>String</td>
                        <td>CLAIMED,
                          VALID,
                          INVALID,
                          REVOKED,
                          SUSPENDED;
                          </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >uin</th>
                        <td>String</td>
                        <td>11 digits
                        </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >identityTransactionId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          -</td>
                      </tr>

                      <tr>
                        <th>title</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>enrolmentTrackingId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>firstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>lastName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>middleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>otherName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>maidenName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousFirstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousLastName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousMiddleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>dateOfBirth</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>gender</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>maritalStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>spouseNin</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>height</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>religion</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>emailAddress</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>telephoneNumber</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nativeSpokenLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>otherSpokenLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>readwriteLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>educationLevel</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>profession</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>employmentStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nationality</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>personId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>enrolmentDate</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>biometricDataList</th>
                        <td>Lit of Biometric Data:
                          -Type
                          -referencePath
                        </td>
                        <td>FACE,
                          FINGERPRINT,
                          IRIS,
                          VOICE,
                          SIGNATURE;
                          </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>identityId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>reasonForIdentityStatusChange</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinFirstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinSurname</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinMiddleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinRelation</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinAddressLine1</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinAddressLine2</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinTown</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinPostCode</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originPlace</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>ResidenceAddress1</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>ResidenceAddress2</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceTown</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>operatorUserId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>physicalStatusVerification</th>
                        <td>String</td>
                        <td>ALIVE | DEAD</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>deathVerification</th>
                        <td>String</td>
                        <td>DERIVED | REPORTED | VERIFIED</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 " ><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{death_report_sample_message}}</code></pre>
                  </div>
              </div>
            </div>

            <h5 id="" class="text-header mb-3 fw-bold fs-20">Suspension Event</h5>
            <div class="row border-bottom mb-5 pb-3 " style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >pr.person.update</strong> - This event is emitted upon the update (suspension) of an existing Person in the Population registry
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>identityStatus</th>
                        <td>String</td>
                        <td>CLAIMED,
                          VALID,
                          INVALID,
                          REVOKED,
                          SUSPENDED;
                          </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >uin</th>
                        <td>String</td>
                        <td>11 digits
                        </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >identityTransactionId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          -</td>
                      </tr>

                      <tr>
                        <th>title</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>enrolmentTrackingId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>firstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>lastName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>middleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>otherName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>maidenName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousFirstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousLastName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousMiddleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>dateOfBirth</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>gender</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>maritalStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>spouseNin</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>height</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>religion</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>emailAddress</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>telephoneNumber</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nativeSpokenLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>otherSpokenLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>readwriteLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>educationLevel</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>profession</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>employmentStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nationality</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>personId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>enrolmentDate</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>biometricDataList</th>
                        <td>Lit of Biometric Data:
                          -Type
                          -referencePath
                        </td>
                        <td>FACE,
                          FINGERPRINT,
                          IRIS,
                          VOICE,
                          SIGNATURE;
                          </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>identityId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>reasonForIdentityStatusChange</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinFirstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinSurname</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinMiddleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinRelation</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinAddressLine1</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinAddressLine2</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinTown</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinPostCode</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originPlace</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>ResidenceAddress1</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>ResidenceAddress2</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceTown</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>operatorUserId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>physicalStatusVerification</th>
                        <td>String</td>
                        <td>ALIVE, DEAD</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 " ><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{suspension_event_sample_message}}</code></pre>
                  </div>
              </div>
            </div>

            <h5 id="" class="text-header mb-3 fw-bold fs-20">Invalidation Event</h5>
            <div class="row  mb-5 pb-3 " >
              <div class="col-md-7">
                <div class="mb-4 ">
                  <div class="mb-2">
                    <strong >pr.person.update</strong> - This event is emitted upon the Update (invalidation) of an existing Person in the Population registry

                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Field Name</th>
                        <th scope="col">Field Type</th>
                        <th scope="col">Restrictions</th>
                        <th scope="col">Format Validation</th>
                        <th scope="col">Required / Optional / Conditional</th>
                        <th scope="col">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>identityStatus</th>
                        <td>String</td>
                        <td>CLAIMED,
                          VALID,
                          INVALID,
                          REVOKED,
                          SUSPENDED;
                          </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >uin</th>
                        <td>String</td>
                        <td>11 digits
                        </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th >identityTransactionId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>
                          -</td>
                      </tr>

                      <tr>
                        <th>title</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>enrolmentTrackingId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>firstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>lastName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>middleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>otherName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>maidenName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousFirstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousLastName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>previousMiddleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>dateOfBirth</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>gender</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>birthLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>maritalStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>spouseNin</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>height</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>religion</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>emailAddress</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>telephoneNumber</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nativeSpokenLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>otherSpokenLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>readwriteLanguage</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>educationLevel</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>profession</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>employmentStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nationality</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>personId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>enrolmentDate</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>biometricDataList</th>
                        <td>Lit of Biometric Data:
                          -Type
                          -referencePath
                        </td>
                        <td>FACE,
                          FINGERPRINT,
                          IRIS,
                          VOICE,
                          SIGNATURE;
                          </td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>identityId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>reasonForIdentityStatusChange</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceStatus</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinFirstName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinSurname</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinMiddleName</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinRelation</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinAddressLine1</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinAddressLine2</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinTown</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>nextOfKinPostCode</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originPlace</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>originCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>ResidenceAddress1</th>
                        <td>String</td>
                        <td>-</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>ResidenceAddress2</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceTown</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceLga</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceState</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>residenceCountry</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>operatorUserId</th>
                        <td>String</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Optional</td>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>physicalStatusVerification</th>
                        <td>String</td>
                        <td>ALIVE | DEAD</td>
                        <td>Not Blank</td>
                        <td>Required</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="fs-16 mb-3 " ><strong>Sample Message</strong></div>
                <div class="bg-dark text-white p-3 mb-4 fs-14">
                  <pre><code>{{invalidation_event_sample_message}}</code></pre>
                  </div>
              </div>
            </div>

          </div>
        </div>
    </div>
  </div>
</div>
