/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MessageDashboardPojo { 
    totalActiveTopics?: number;
    totalMessagesProcessed?: number;
    totalMessagesDiscarded?: number;
    totalSystemsSubscribed?: number;
    lastQueueOptimizationCronDate?: string;
    lastMessageProcessTime?: string;
    nextQueueOptimizationCron?: string;
    totalMessagesInQueue?: number;
    totalActiveProducers?: number;
    totalActiveConsumers?: number;
    dbUsedPercentage?: number;
    dbUsedLastUpdated?: string;
    eventStoreUsedPercentage?: number;
    eventStoreUsedLastUpdated?: string;
}

