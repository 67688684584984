/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientTopicConfiguration } from './client-topic-configuration';


export interface ApiClientPojo { 
    id?: number;
    name?: string;
    clientCode?: string;
    clientSecret?: string;
    description?: string;
    scopes?: Array<ApiClientPojo.ScopesEnum>;
    isProducer?: boolean;
    dateCreated?: string;
    status?: ApiClientPojo.StatusEnum;
    portalAccountCode?: string;
    systemType?: ApiClientPojo.SystemTypeEnum;
    topicConfigurations?: Array<ClientTopicConfiguration>;
}
export namespace ApiClientPojo {
    export type ScopesEnum = 'CREATE_USER' | 'CREATE_TOPIC' | 'LIST_TOPICS' | 'PUBLISH' | 'SUBSCRIBE' | 'LIST_SUBSCRIPTIONS';
    export const ScopesEnum = {
        CreateUser: 'CREATE_USER' as ScopesEnum,
        CreateTopic: 'CREATE_TOPIC' as ScopesEnum,
        ListTopics: 'LIST_TOPICS' as ScopesEnum,
        Publish: 'PUBLISH' as ScopesEnum,
        Subscribe: 'SUBSCRIBE' as ScopesEnum,
        ListSubscriptions: 'LIST_SUBSCRIPTIONS' as ScopesEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type SystemTypeEnum = 'GATEWAY_CONTROLLER' | 'POPULATION_REGISTRY' | 'THIRD_PARTY_SYSTEM' | 'UIN_GENERATOR' | 'ENROLLMENT_SERVER' | 'CIVIL_REGISTRY' | 'CREDENTIAL_MANAGEMENT_SYSTEM';
    export const SystemTypeEnum = {
        GatewayController: 'GATEWAY_CONTROLLER' as SystemTypeEnum,
        PopulationRegistry: 'POPULATION_REGISTRY' as SystemTypeEnum,
        ThirdPartySystem: 'THIRD_PARTY_SYSTEM' as SystemTypeEnum,
        UinGenerator: 'UIN_GENERATOR' as SystemTypeEnum,
        EnrollmentServer: 'ENROLLMENT_SERVER' as SystemTypeEnum,
        CivilRegistry: 'CIVIL_REGISTRY' as SystemTypeEnum,
        CredentialManagementSystem: 'CREDENTIAL_MANAGEMENT_SYSTEM' as SystemTypeEnum
    };
}


