/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TopicRequestSearchResponsePojo { 
    topicName?: string;
    topicStatus?: TopicRequestSearchResponsePojo.TopicStatusEnum;
    systemEventClass?: TopicRequestSearchResponsePojo.SystemEventClassEnum;
    dueDate?: string;
    avgMessageSize?: number;
    maxMessageSize?: number;
    maxRetryCount?: number;
    retryIntervalInSeconds?: number;
    systemTopic?: boolean;
    id?: number;
    type?: TopicRequestSearchResponsePojo.TypeEnum;
    status?: TopicRequestSearchResponsePojo.StatusEnum;
    requestReason?: string;
    reviewedBy?: string;
    reviewedReason?: string;
    reviewedAt?: string;
    requestedAt?: string;
    requestedBy?: string;
}
export namespace TopicRequestSearchResponsePojo {
    export type TopicStatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const TopicStatusEnum = {
        Active: 'ACTIVE' as TopicStatusEnum,
        Inactive: 'INACTIVE' as TopicStatusEnum,
        Deleted: 'DELETED' as TopicStatusEnum
    };
    export type SystemEventClassEnum = 'ENROLLMENT' | 'CREDENTIAL' | 'CUSTOMER' | 'PARTNER_VENDOR' | 'BILLING' | 'TOKENIZATION' | 'UIN' | 'REFERENCE' | 'VERIFICATION' | 'OTHER';
    export const SystemEventClassEnum = {
        Enrollment: 'ENROLLMENT' as SystemEventClassEnum,
        Credential: 'CREDENTIAL' as SystemEventClassEnum,
        Customer: 'CUSTOMER' as SystemEventClassEnum,
        PartnerVendor: 'PARTNER_VENDOR' as SystemEventClassEnum,
        Billing: 'BILLING' as SystemEventClassEnum,
        Tokenization: 'TOKENIZATION' as SystemEventClassEnum,
        Uin: 'UIN' as SystemEventClassEnum,
        Reference: 'REFERENCE' as SystemEventClassEnum,
        Verification: 'VERIFICATION' as SystemEventClassEnum,
        Other: 'OTHER' as SystemEventClassEnum
    };
    export type TypeEnum = 'ACTIVATE_TOPIC' | 'DEACTIVATE_TOPIC' | 'DELETE_TOPIC' | 'DEACTIVATE_SUBSCRIPTION' | 'DELETE_SUBSCRIPTION' | 'ACTIVATE_USER' | 'DEACTIVATE_USER';
    export const TypeEnum = {
        ActivateTopic: 'ACTIVATE_TOPIC' as TypeEnum,
        DeactivateTopic: 'DEACTIVATE_TOPIC' as TypeEnum,
        DeleteTopic: 'DELETE_TOPIC' as TypeEnum,
        DeactivateSubscription: 'DEACTIVATE_SUBSCRIPTION' as TypeEnum,
        DeleteSubscription: 'DELETE_SUBSCRIPTION' as TypeEnum,
        ActivateUser: 'ACTIVATE_USER' as TypeEnum,
        DeactivateUser: 'DEACTIVATE_USER' as TypeEnum
    };
    export type StatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const StatusEnum = {
        Approved: 'APPROVED' as StatusEnum,
        Pending: 'PENDING' as StatusEnum,
        Disapproved: 'DISAPPROVED' as StatusEnum
    };
}


