/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { MessageDashboardPojo } from '../model/models';
import { QueryResultsPendingMessagesPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface DiskSpaceNotificationRequestParams {
    sysID: string;
    body: string;
}

export interface SearchPendingMessagesRequestParams {
    lastUpdatedBy?: string;
    nextNotificationTime?: string;
    lastUpdatedAt?: string;
    retryCount?: number;
    eventNotification?: string;
    sentAt?: string;
    subscription?: string;
    subscriptionTopicName?: string;
    lastNotificationAttempt?: string;
    acknowledgedAt?: string;
    deliveredAt?: string;
    deactivatedBy?: string;
    maxRetry?: number;
    createdAt?: string;
    createdBy?: string;
    dateDeactivated?: string;
    topicName?: string;
    retryInterval?: number;
    id?: number;
    deliveryStatus?: 'PENDING' | 'SENDING' | 'ACKNOWLEDGED';
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    offset?: number;
    limit?: number;
    createdBefore?: string;
    createdAfter?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class MessagesControllerService {

    protected basePath = 'http://eme.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public diskSpaceNotification(requestParameters: DiskSpaceNotificationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public diskSpaceNotification(requestParameters: DiskSpaceNotificationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public diskSpaceNotification(requestParameters: DiskSpaceNotificationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public diskSpaceNotification(requestParameters: DiskSpaceNotificationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const sysID = requestParameters.sysID;
        if (sysID === null || sysID === undefined) {
            throw new Error('Required parameter sysID was null or undefined when calling diskSpaceNotification.');
        }
        const body = requestParameters.body;
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling diskSpaceNotification.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/messages/${encodeURIComponent(String(sysID))}/disk-space-usage-notification`,
            body,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDashboardSummary(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<MessageDashboardPojo>;
    public getDashboardSummary(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<MessageDashboardPojo>>;
    public getDashboardSummary(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<MessageDashboardPojo>>;
    public getDashboardSummary(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<MessageDashboardPojo>(`${this.configuration.basePath}/messages/dashboard`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPendingMessages(requestParameters: SearchPendingMessagesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsPendingMessagesPojo>;
    public searchPendingMessages(requestParameters: SearchPendingMessagesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsPendingMessagesPojo>>;
    public searchPendingMessages(requestParameters: SearchPendingMessagesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsPendingMessagesPojo>>;
    public searchPendingMessages(requestParameters: SearchPendingMessagesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const nextNotificationTime = requestParameters.nextNotificationTime;
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const retryCount = requestParameters.retryCount;
        const eventNotification = requestParameters.eventNotification;
        const sentAt = requestParameters.sentAt;
        const subscription = requestParameters.subscription;
        const subscriptionTopicName = requestParameters.subscriptionTopicName;
        const lastNotificationAttempt = requestParameters.lastNotificationAttempt;
        const acknowledgedAt = requestParameters.acknowledgedAt;
        const deliveredAt = requestParameters.deliveredAt;
        const deactivatedBy = requestParameters.deactivatedBy;
        const maxRetry = requestParameters.maxRetry;
        const createdAt = requestParameters.createdAt;
        const createdBy = requestParameters.createdBy;
        const dateDeactivated = requestParameters.dateDeactivated;
        const topicName = requestParameters.topicName;
        const retryInterval = requestParameters.retryInterval;
        const id = requestParameters.id;
        const deliveryStatus = requestParameters.deliveryStatus;
        const status = requestParameters.status;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const createdBefore = requestParameters.createdBefore;
        const createdAfter = requestParameters.createdAfter;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (nextNotificationTime !== undefined && nextNotificationTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>nextNotificationTime, 'nextNotificationTime');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (retryCount !== undefined && retryCount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>retryCount, 'retryCount');
        }
        if (eventNotification !== undefined && eventNotification !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>eventNotification, 'eventNotification');
        }
        if (sentAt !== undefined && sentAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sentAt, 'sentAt');
        }
        if (subscription !== undefined && subscription !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subscription, 'subscription');
        }
        if (subscriptionTopicName !== undefined && subscriptionTopicName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>subscriptionTopicName, 'subscription.topic.name');
        }
        if (lastNotificationAttempt !== undefined && lastNotificationAttempt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastNotificationAttempt, 'lastNotificationAttempt');
        }
        if (acknowledgedAt !== undefined && acknowledgedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>acknowledgedAt, 'acknowledgedAt');
        }
        if (deliveredAt !== undefined && deliveredAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deliveredAt, 'deliveredAt');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (maxRetry !== undefined && maxRetry !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>maxRetry, 'maxRetry');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (topicName !== undefined && topicName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>topicName, 'topicName');
        }
        if (retryInterval !== undefined && retryInterval !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>retryInterval, 'retryInterval');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (deliveryStatus !== undefined && deliveryStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deliveryStatus, 'deliveryStatus');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsPendingMessagesPojo>(`${this.configuration.basePath}/messages/pending-messages`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
