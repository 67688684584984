import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {ForbiddenComponent} from "./modules/extranet/error-pages/forbidden/forbidden.component";
import {NotFoundComponent} from './modules/extranet/error-pages/not-found/not-found.component';
import {LandingPageComponent} from './modules/extranet/landing-page/landing-page.component';
import { WelcomePageComponent } from './modules/extranet/welcome-page/welcome-page.component';
import {LoggedInGuard} from "./shared/guard/logged-in.guard";
import {ApiGuideComponent} from "./modules/extranet/api-guide/api-guide.component";
import {MultiFactorAuthenticationGuard} from "./shared/guard/multi-factor-authentication-guard.service";


const routes: Routes = [
  {
    path: 'sign-in',
    component: LandingPageComponent,
    pathMatch: 'full',
    data: {
      isExtranet: false
    },
  },

  {
    path: '',
    component: WelcomePageComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    },
  },

  {
    path: 'developer-guide',
    component: ApiGuideComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    },
  },

  {
    path: '',
    loadChildren: () =>
      import('./modules/intranet/intranet.module').then((m) => m.IntranetModule),
    canActivate: [LoggedInGuard, MultiFactorAuthenticationGuard],
    data: {
      isExtranet: false
    },
  },
  {
    path: '2fa/fingerprint',
    data: {
      isExtranet: true,
      hideLayout: true,
      hideFooter: true
    },
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./shared/fingerprint-authentication/fingerprint-authentication-routing.module').then(m => m.FingerprintAuthenticationRoutingModule),
  },
  {
    path: 'download-plugin',
    data: {
      isExtranet: true,
      hideLayout: true,
    },
    canActivate: [],
    loadChildren: () => import('./modules/extranet/biometric-auth-plugin/biometric-auth-plugin.module').then(m => m.BiometricAuthPluginModule),
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    data: {
      isExtranet: true
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      isExtranet: false
    },
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
