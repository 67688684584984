<section class="hero-bg">
    <div class="container pb-3">
      <div class="row pt-5  align-items-center">
        <div class="col-lg-7">
          <div class="mx-auto" style="max-width: 700px;">
            <h1 class="mb-4 fw-bold" style="font-size: calc(2rem + 1.6vw)!important;">Welcome to the Enterprise Messaging Engine!</h1>

            <p class="mb-3" style="max-width: 543px;">
              This is the the data integration system for the NIMC ID Ecosystem components. 
            </p>
            <p class="mb-3" style="max-width: 543px;">
              The API guide gives necessary information to successfully integrate a component to the ID Ecosystem.
            </p>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="hero-img-wrapper text-center bg-transparent" style="max-width: 550px;">
            <img src="../assets/img/mvrd-home-img.svg" class="img-fluid mb-4 d-dark-none" alt="MVRD Homepage Image" />
            <img src="../assets/img/mvrd-home-img-dark.svg" class="img-fluid mb-4 d-light-none" alt="MVRD Homepage Image" />
          </div>
        </div>
      </div>
    </div>
</section>