/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActivityLogDto { 
    username?: string;
    action: ActivityLogDto.ActionEnum;
    userId?: string;
}
export namespace ActivityLogDto {
    export type ActionEnum = 'LOGIN' | 'LOGOUT' | 'CREATE_USER' | 'CREATE_TOPIC' | 'VIEW_TOPIC' | 'CREATE_API_CLIENT' | 'VIEW_CLIENT_DETAILS' | 'EDIT_CLIENT' | 'DEACTIVATE_CLIENT' | 'ADD_ROLE' | 'APPROVE_NEW_TOPIC_REQUEST' | 'DISAPPROVE_NEW_TOPIC_REQUEST' | 'ACTIVATE_CLIENT' | 'UPDATE_CLIENT' | 'VIEW_SUBSCRIPTION' | 'UNSUBSCRIBE_FROM_SUBSCRIPTION' | 'REACTIVATE_SUBSCRIPTION' | 'DELETE_FROM_SUBSCRIPTION' | 'UPDATE_SETTING' | 'ACTIVATE_TOPIC_REQUEST' | 'DEACTIVATE_TOPIC_REQUEST' | 'DELETE_TOPIC_REQUEST' | 'APPROVE_TOPIC_REQUEST' | 'DISAPPROVE_TOPIC_REQUEST' | 'API_CLIENT_SUBSCRIPTION' | 'API_CLIENT_SUBSCRIPTION_CONFIRMATION' | 'API_CLIENT_UNSUBSCRIPTION';
    export const ActionEnum = {
        Login: 'LOGIN' as ActionEnum,
        Logout: 'LOGOUT' as ActionEnum,
        CreateUser: 'CREATE_USER' as ActionEnum,
        CreateTopic: 'CREATE_TOPIC' as ActionEnum,
        ViewTopic: 'VIEW_TOPIC' as ActionEnum,
        CreateApiClient: 'CREATE_API_CLIENT' as ActionEnum,
        ViewClientDetails: 'VIEW_CLIENT_DETAILS' as ActionEnum,
        EditClient: 'EDIT_CLIENT' as ActionEnum,
        DeactivateClient: 'DEACTIVATE_CLIENT' as ActionEnum,
        AddRole: 'ADD_ROLE' as ActionEnum,
        ApproveNewTopicRequest: 'APPROVE_NEW_TOPIC_REQUEST' as ActionEnum,
        DisapproveNewTopicRequest: 'DISAPPROVE_NEW_TOPIC_REQUEST' as ActionEnum,
        ActivateClient: 'ACTIVATE_CLIENT' as ActionEnum,
        UpdateClient: 'UPDATE_CLIENT' as ActionEnum,
        ViewSubscription: 'VIEW_SUBSCRIPTION' as ActionEnum,
        UnsubscribeFromSubscription: 'UNSUBSCRIBE_FROM_SUBSCRIPTION' as ActionEnum,
        ReactivateSubscription: 'REACTIVATE_SUBSCRIPTION' as ActionEnum,
        DeleteFromSubscription: 'DELETE_FROM_SUBSCRIPTION' as ActionEnum,
        UpdateSetting: 'UPDATE_SETTING' as ActionEnum,
        ActivateTopicRequest: 'ACTIVATE_TOPIC_REQUEST' as ActionEnum,
        DeactivateTopicRequest: 'DEACTIVATE_TOPIC_REQUEST' as ActionEnum,
        DeleteTopicRequest: 'DELETE_TOPIC_REQUEST' as ActionEnum,
        ApproveTopicRequest: 'APPROVE_TOPIC_REQUEST' as ActionEnum,
        DisapproveTopicRequest: 'DISAPPROVE_TOPIC_REQUEST' as ActionEnum,
        ApiClientSubscription: 'API_CLIENT_SUBSCRIPTION' as ActionEnum,
        ApiClientSubscriptionConfirmation: 'API_CLIENT_SUBSCRIPTION_CONFIRMATION' as ActionEnum,
        ApiClientUnsubscription: 'API_CLIENT_UNSUBSCRIPTION' as ActionEnum
    };
}


