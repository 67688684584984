import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ActivityLogSearchControllerService } from './api/activity-log-search-controller.service';
import { ApiClientControllerService } from './api/api-client-controller.service';
import { AuditTrailControllerService } from './api/audit-trail-controller.service';
import { ClientTopicConfigurationControllerService } from './api/client-topic-configuration-controller.service';
import { DashboardControllerService } from './api/dashboard-controller.service';
import { MessagesControllerService } from './api/messages-controller.service';
import { PortalAccountControllerService } from './api/portal-account-controller.service';
import { PublisherService } from './api/publisher.service';
import { SettingsControllerService } from './api/settings-controller.service';
import { SubscriberService } from './api/subscriber.service';
import { SubscriptionControllerService } from './api/subscription-controller.service';
import { TopicsControllerService } from './api/topics-controller.service';
import { UserControllerService } from './api/user-controller.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
