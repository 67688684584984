/**
 * EME CMD API
 * The EME CMD RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: software@nimc.gov.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SubscriptionPojo { 
    /**
     * The unique ID of the subscription
     */
    uuid?: string;
    /**
     * Topic unique ID
     */
    topic?: string;
    protocol?: SubscriptionPojo.ProtocolEnum;
    address?: string;
    policy?: string;
    /**
     * Status indicating if the subscription was confirmed or not.
     */
    active?: boolean;
}
export namespace SubscriptionPojo {
    export type ProtocolEnum = 'http' | 'email';
    export const ProtocolEnum = {
        Http: 'http' as ProtocolEnum,
        Email: 'email' as ProtocolEnum
    };
}


