import {Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";
import {KeycloakService} from "keycloak-angular";
import {PageManager} from "../../../shared/misc/page-manager";
import {UserAccount} from "../../../shared/components/models/user-account.model";

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.css']
})
export class SiteHeaderComponent implements OnInit {
  user: any;
  routeData: any;
  apiGuide: boolean = false;

  currentAccount: UserAccount;

  constructor(private router: Router, route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private keycloak: KeycloakService, private pageManager: PageManager) {
    router.events.forEach(e => {
      if (e instanceof NavigationEnd) {
        this.routeData = route?.root?.firstChild?.snapshot?.data;
      }
    })
  }

  ngOnInit(): void {
    this.pageManager.currentUserAccount$
      .subscribe(userAccount => {
        this.currentAccount = userAccount;
      });
    this.authenticationService.getUser()
      .subscribe(value => {
        this.user = value;
        if (this.currentAccount) {
          if (this.user && this.user.accounts.some((u: { code: string; }) => u.code === this.currentAccount.code)) {
            this.pageManager.setIntegration(this.user.accounts.find((u: { code: string; }) => u.code === this.currentAccount.code));
          }
          this.pageManager.currentUserAccount$.next(this.currentAccount);
        } else if (this.user) {
          this.currentAccount = this.user.accounts[0];
          this.pageManager.setCurrentUserAccount(this.user.accounts[0]);
        }

      });
  }

  async login() {
    this.authenticationService.login({
      redirectUri: window.location.origin + '/dashboard',
      prompt: 'login'
    });
  }

  async dashboard() {
    this.router.navigateByUrl('/dashboard')
  }

  async logout() {
    this.authenticationService.logout();
    // await this.keycloak.getKeycloakInstance().logout({})
  }

  goToDashboard() {
    this.router.navigate(['/dashboard'])
  }

  setHeaderClass() {
    let headerClass = '';

    if (this.routeData?.fixedHeader) {
      headerClass += ' header-fixed fixed-on-scroll'
    }
    if (!(this.routeData?.isExtranet)) {
      headerClass += ' intranet-nav'
    }

    return headerClass;
  }

  setApiGuideActive() {
    this.apiGuide = true;
  }

  resetApiGuideActive() {
    this.apiGuide = false;
  }
}
